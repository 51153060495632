import React from 'react'
import { Link } from 'react-router-dom'

import styles from './styles.module.scss'

type Props = {
  title: string
  subtitle: string
  link: string
  icon: string | React.ReactNode
  iconColor?: string
}

export const Card: React.FC<Props> = ({ title, subtitle, link, icon }) => {
  return (
    <Link className={styles.container} to={link}>
      <div className={styles.column}>
        <b>{title}</b>
        <small className={styles.subtitle}>{subtitle}</small>
      </div>
      <span className={styles.icon}>{icon}</span>
    </Link>
  )
}
