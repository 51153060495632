import { monoDoubleDict } from './constants'
import { sansDict, sansBoldItalicDict, monospaceDict } from '../constants'

export const mono = (text: string = 'Underline'): string => {
  return (
    text
      .split('')
      .map(e => monospaceDict[e] || e)
      .join('\u0332') + '\u0332'
  )
}

export const monoDouble = (text: string = 'Double Underline'): string => {
  return (
    '\u200B\u0333' +
    text
      .split('')
      .map(e => monoDoubleDict[e] || e)
      .join('\u200B\u0333') +
    '\u200B\u0333'
  )
}

export const monoArrow = (text: string = 'Monospaced with Arrow'): string => {
  return (
    text
      .split('')
      .map(e => monospaceDict[e] || e)
      .join('\u0332') + '\u0362'
  )
}

export const underlined = (text: string = 'Sans-serif underline'): string => {
  return (
    '\u0332' +
    text
      .split('')
      .map(e => sansDict[e] || e)
      .join('\u0332') +
    '\u0332'
  )
}

export const doubleUnderline = (text: string = 'sans-serif double underline'): string => {
  return (
    '\u200B\u0333' +
    text
      .split('')
      .map(e => sansDict[e] || e)
      .join('\u200B\u0333') +
    '\u200B\u0333'
  )
}

export const underlinedArrow = (text: string = 'Sans-serif underline with arrow'): string => {
  return (
    '\u0332' +
    text
      .split('')
      .map(e => sansDict[e] || e)
      .join('\u0332') +
    '\u0362'
  )
}

export const hyperbole = (text: string = 'Hyperbole'): string => {
  return (
    text
      .split('')
      .map(e => sansBoldItalicDict[e] || e)
      .join('\u0332') + '\u0332'
  )
}

export const silly = (text: string = 'Super hyperbole'): string => {
  return (
    text
      .split('')
      .map(e => sansBoldItalicDict[e] || e)
      .join('\u200B\u0333') + '\u200B'
  )
}

export const sillyWithHands = (text: string = 'ridiculous'): string => {
  return (
    '\u261B\u200B\u0333' +
    text
      .split('')
      .map(e => sansBoldItalicDict[e] || e)
      .join('\u200B\u0333') +
    '\u200A\u0333\u261A'
  )
}

export const unstyled = (text: string = 'unstyled'): string => {
  return text.split('').join('\u200B\u0332') + '\u200B\u0332'
}

export const unstyledWithoutFix = (text: string = 'unstyled'): string => {
  return text.split('').join('\u0332') + '\u0332'
}
