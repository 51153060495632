import { superScriptDict, superScriptDict2 } from './constants'

export const superscript = (text: string = 'superscript', underline: string = ''): string => {
  return text
    .split('')
    .map(e => superScriptDict[e] || e)
    .join(underline)
}

export const superscript2 = (text: string = 'superscript', underline: string = ''): string => {
  return text
    .split('')
    .map(e => superScriptDict2[e] || e)
    .join(underline)
}
