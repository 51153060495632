export const original = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z', 'A',
  'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z']
export const upsideDownDict = ['ɐ', 'q', 'ɔ', 'p', 'ǝ', 'ɟ', 'ƃ', 'ɥ', 'ᴉ', 'ɾ', 'ʞ', 'l', 'ɯ', 'u', 'o', 'd', 'b', 'ɹ', 's', 'ʇ', 'n', 'ʌ', 'ʍ', 'x', 'ʎ', 'z',
  '∀', '𐐒', 'Ɔ', 'ᗡ', 'Ǝ', 'Ⅎ', 'פ', 'H', 'I', 'ſ', 'ꓘ', '˥', 'W', 'N', 'O', 'Ԁ', 'Ỏ', 'ᴚ', 'S', '┴', '∩', 'Λ', 'M', 'X', '⅄', 'Z']

export const tags = [
  { name: 'Unicode' },
  { name: 'Tools' },
  { name: 'Fonts' },
  { name: 'Hacks' },
  { name: 'Text' },
  { name: 'Meta' }
]

export const table = [
  ['', '', 'Origin'],
  ['a', 'ɐ', 'Flipped Latin “a” – used as a symbol in the International Phonetic Alphabet (IPA)'],
  ['b', 'q', 'Just the letter “q”'],
  ['c', 'ɔ', 'Latin Small Letter Open “o” — another symbol from the IPA'],
  ['d', 'p', 'Just the letter “p”'],
  ['e', 'ǝ', 'An extension of the Latin alphabet used in the Pan-Nigerian alphabet and African Reference Alphabet'],
  ['f', 'ɟ', 'Latin dotless “j” with a stroke — from the IPA'],
  ['g', 'ƃ', 'Latin small letter “b” with top bar — used briefly in the Zhuang alphabet to represent a voiced bilabial implosive before being replaced with “mb”'],
  ['h', 'ɥ', 'Small Latin Turned “h” — IPA'],
  ['i', 'ᴉ', 'Small Latin Turned “i” — used in the Uralic Phonetic Alphabet'],
  ['j', 'ɾ', 'Latin Small “r” with Fishhook — IPA'],
  ['k', 'ʞ', 'Latin Small Turned “k” — IPA'],
  ['l', 'l', 'Just a lowercase “l” — works in most fonts, with the exception of some serif fonts and all mono-space fonts'],
  ['m', 'ɯ', 'Latin Small Letter Turned “m”'],
  ['n', 'u', 'Just the letter “u”'],
  ['o', 'o', 'Stays the same'],
  ['p', 'd', 'Just the letter “d”'],
  ['q', 'b', 'Just the letter “b”'],
  ['r', 'ɹ', 'Latin small turned “r” — IPA'],
  ['s', 's', 'Stays the same'],
  ['t', 'ʇ', 'Latin Small Turned “t” — IPA'],
  ['u', 'n', 'Just the letter “n”'],
  ['v', 'ʌ', 'Latin Small Turned “v” — IPA'],
  ['w', 'ʍ', 'Latin Small Turned “w” — IPA'],
  ['x', 'x', 'Stays the same'],
  ['y', 'ʎ', 'Latin Small Turned “y” — IPA'],
  ['z', 'z', 'Stays the same']
]

export const table2 = [
  ['A', '∀', 'The logic symbol for universal quantification, essentially meaning “for every” or “for all.” Also used in the IPA for an “open-backed rounded vowel.”'],
  ['B', '𐐒', 'Deseret capital letter “B” — a phonetic alphabet developed by the Mormon Church under the eye of Brigham Young'],
  ['C', 'Ɔ', 'Latin Capital Open “O” — used as part of an extension to the Latin alphabet in the Yacatec Mayan alphabet and the African Reference Alphabet'],
  ['D', 'ᗡ', 'Canadian syllabics Carrier Tha — part of a set of characters for writing aboriginal Canadian languages like Inuktitut and Carrier'],
  ['E', 'Ǝ', 'Latin Capital Reversed “E” — IPA'],
  ['F', 'Ⅎ', 'Turned Capital “F” — introduced to the Roman Empire by Emperor Claudius, it denotes the sound a “W” or “V” in English'],
  ['G', 'פ', 'Hebrew letter Pe'],
  ['H', 'H', 'Stays the same'],
  ['I', 'I', 'Stays the same'],
  ['J', 'ſ', 'Latin Small Long “s” — once used for the first character in a double “ss” for words like ‘eſsay’'],
  ['K', 'ꓘ', 'Lisu letter Kha — part of the Fraser Lisu alphabet invented by preacher Sara Ba Thaw, to write Lisu, a Tibeto-Burman language, in Roman letters'],
  ['L', '˥', 'Modifier Letter Extra-High Tone Bar — used in conjunction with the IPA to represent a high-pitched tone'],
  ['M', 'W', 'Just the letter “W”'],
  ['N', 'N', 'Stays the same'],
  ['O', 'O', 'Stays the same'],
  ['P', 'Ԁ', 'Cyrillic capital “Komi De”'],
  ['Q', 'Ỏ', 'Latin Capital Letter “O” with Hook Above'],
  ['R', 'ᴚ', 'Latin Small Capital Turned “R” — IPA'],
  ['S', 'S', 'Stays the same'],
  ['T', '⊥', 'Up Tack — a mathematical symbol used to mean a constant, usually one that has faulty logic or absurdum'],
  ['U', '∩', 'Intersection — a mathematical symbol in set theory used in the middle of a Venn diagram'],
  ['V', 'Λ', 'Greek capital letter Lambda'],
  ['W', 'M', 'Just the letter “M”'],
  ['X', 'X', 'Stays the same'],
  ['Y', '⅄', 'Turned Sans-Serif Capital Y'],
  ['Z', 'Z', 'Stays the same']
]
