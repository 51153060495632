import classNames from 'classnames'
import { type Tool } from '../components/MoreTools'
import styles from './tools.module.scss'

export const calendarTool: Tool = {
  name: 'Unicode calendar generator',
  variantCount: 5,
  text:
`╔════════════════════╗
║ November ░░░░ 2023 ║
╟──┬──┬──┬──┬──┬──┬──╢
║░░│░░│░░│01│02│03│04║
╟──┼──┼──┼──┼──┼──┼──╢
║05│06│07│08│09│10│11║
╟──┼──┼──┼──┼──┼──┼──╢
║12│13│14│15│16│17│18║
╟──┼──┼──┼──┼──╔══╗──╢
║19│20│21│22│23║24║25║
╟──┼──┼──┼──┼──╚══╝──╢
║26│27│28│29│30│░░│░░║
╚══╧══╧══╧══╧══╧══╧══╝`,
  additionalText: 'Create custom calendars for special events, project planning, and more.',
  link: '/calendar/',
  className: classNames(styles.mono, styles.calendar)
}

export const boldTextTool: Tool = {
  name: 'Bold text generator',
  variantCount: 6,
  text: 'A tool for creating 𝘽𝙤𝙡𝙙 𝙏𝙚𝙭𝙩 that works anywhere online.',
  link: '/bold-text/'
}

export const starSymbolsTool: Tool = {
  name: 'Star symbols',
  variantCount: 'Multiple',
  text: 'Copy and paste Unicode stars including Dingbat symbols ✰ pentagrams ⛤ religious stars ✡ and more ✶ ✵ ✸ ✮ ✪',
  link: '/star-symbols/'
}

export const snowflakeSymbolsTool: Tool = {
  name: 'Snowflake symbols',
  variantCount: 'Multiple',
  text: 'Copy and paste Unicode snowflakes ❅ ❆ ❃ ❊ ❉ and other winter ☃️ and Christmas 🎅 symbols and emojis.',
  link: '/snowflake-text-symbols/'
}

export const underlineTextTool: Tool = {
  name: 'Underline generator',
  variantCount: 11,
  text: 'Use underlined text on social media and other places you\'re 𝚗̲𝚘̲𝚝̲ ̲𝚗̲𝚘̲𝚛̲𝚖̲𝚊̲𝚕̲𝚕̲𝚢̲ ̲𝚊̲𝚕̲𝚕̲𝚘̲𝚠̲𝚎̲𝚍̲.',
  link: '/underline-text/'
}

export const smallCapsTool: Tool = {
  name: 'Small caps generator',
  variantCount: 1,
  text: 'Quickly generate, copy, and paste Unicode Sᴍᴀʟʟ Cᴀᴘs.',
  link: '/small-caps/'
}

export const strikethroughGeneratorTool: Tool = {
  name: 'Strikethrough generator',
  variantCount: 7,
  text: 'Create 𝚂̶𝚝̶𝚛̶𝚒̶𝚔̶𝚎̶𝚝̶𝚑̶𝚛̶𝚘̶𝚞̶𝚐̶𝚑̶, 𝚜̷𝚕̷𝚊̷𝚜̷𝚑̷𝚎̷𝚍̷, or ████████ text that works anywhere online including social media posts and bios, emails, and lots more.',
  link: '/strikethrough/'
}

export const italicTextTool: Tool = {
  name: 'Italic text generator',
  variantCount: 5,
  text: 'Use italics in places you normally can\'t, like social media posts and bios, instant messages, and more.',
  link: '/italic/'
}

export const squaredFontTool: Tool = {
  name: 'Squared font generator',
  variantCount: 2,
  text: 'Use 🆂🆀🆄🅰🆁🅴🅳 letters and numbers in emails, social media posts, and other places where you want to create eye-catching headers, bullet points, and text.',
  link: '/square/'
}

export const serifFontTool: Tool = {
  name: 'Serif font generator',
  variantCount: 2,
  text: 'Create text with extending features called "serifs" on each character and apply it anywhere online, including social media bios and posts, browser tabs, and more.',
  link: '/serif/'
}

export const sansSerifFontTool: Tool = {
  name: 'Sans serif font generator',
  variantCount: 4,
  text: 'Create text without extending features called "serifs" on each character and apply it anywhere online, including social media bios and posts, browser tabs, and more.',
  link: '/sans-serif/'
}

export const circledFontTool: Tool = {
  name: 'Circled font generator',
  variantCount: 2,
  text: 'Use 🅒🅘🅡🅒🅛🅔🅓 letters and numbers in emails, social media posts, and other places where you want to create eye-catching headers, bullet points, and text.',
  link: '/circled/'
}

export const upsideDownTextTool: Tool = {
  name: 'Upside down text generator',
  variantCount: 3,
  text: 'Generate flipped text that works anywhere online and is easy to understand — even upside down.',
  link: '/upside-down-text/'
}

export const zalgoTool: Tool = {
  name: 'Zalgo',
  variantCount: 'Multiple',
  text: 'Ť̡ĥ̶̬̙ìͩ̄̍̕ś͙́͗ͯ́ ͚̠͆́ͬ́̅i̧̟̙͇̔̍̉͜s͔͙ͯͪ͞҉͎̀͡ ̩͙ͣͮ̉ͦ̚͞ͅZ̢̟͇͇͂́ͣ̕̚a̰̩ͤ҉̴̴͇͍̊ĺ̨͍̩͎͛̿̈́ͨg̶̦̼͇͕̬̒̾o̸̧̨͈͊ͬͧ ̡̡̼̞̥̒Tͬ̊͛̚͞e̛͙͂͆x̘͌t̓',
  link: '/zalgo-text/',
  className: classNames(styles.zalgo, styles.mono)
}
