import type { Meta } from './types'

export const copyAndPasteMeta: Record<string, Meta> = {
  calendar: {
    title: 'Unicode Calendar Generator 🗓️ — Beautiful Dingbats',
    description: 'Quickly create, copy, and paste unique Unicode text calendars that work everywhere online.',
    h1: 'Unicode calendar generator 📅',
    subtitle: 'Quickly create, copy, and paste unique Unicode text calendars that work everywhere online.'
  },
  'emoji-horoscope': {
    title: 'Emoji Horoscope Generator — Your Weekly Horoscope in Emoji Form',
    description: 'Quickly copy and paste the perfect emoji to communicate your message. Search emojis by emotion, topic, country, issue, and lots more.',
    h1: 'Emoji horoscope icons',
    subtitle: 'Get an emoji horoscope for any astrological sign, then copy and paste it to share anywhere online.'
  },
  checkmarks: {
    title: '✅ Checkmarks Icons ✔ to Copy and Paste — Ticks ☑ and Crosses ✘',
    description: 'Quickly copy and paste check marks, tick symbols, and verification marks. Great for to-do lists or for exercising your democratic right. 🗳',
    h1: 'Check mark text icons',
    subtitle: 'Quickly copy and paste check marks, tick symbols, and verification marks.'
  },
  'chess-symbols': {
    title: 'Chess Text Symbols ♙♘♗♖♕♔♚♛♜♝♞♟ to Copy and Paste',
    description: 'Quickly copy and paste chess pieces and generate Unicode chess boards.',
    h1: 'Chess text symbols',
    subtitle: 'Quickly copy and paste chess pieces and generate Unicode chess boards.'
  },
  interrobang: {
    title: '⁉️ Interrobang Emoji — Exclamation & Question Marks to Copy, Paste',
    description: 'Quickly copy and paste an interrobang. Learn when to use ‽ and how to use ‽ on MacOS and iPhone.',
    h1: 'Interrobang generator',
    subtitle: 'Quickly copy and paste an interrobang anywhere online.'
  },
  'snowflake-text-symbols': {
    title: '❄️ Snowflakes Text Symbols to Copy and Paste ❅ ❆ ❃ ❊ ❉',
    description: 'Copy and paste Unicode snowflakes ❅ ❆ ❃ ❊ ❉ and other winter ☃️ and Christmas 🎅 symbols and emojis.',
    h1: 'Snowflake text symbols',
    subtitle: 'Copy and paste winter and Christmas Unicode characters.'
  },
  'star-symbols': {
    title: '🌟 Star Symbol Text to Copy and Paste ✵ ✰ ✷ ✭ ✧ ✸ ✡ ✮ ❂',
    description: 'Copy and paste Unicode stars, including dingbat symbols ✰ pentagrams ⛤ religious stars ✡ and more. ✶ ✵ ✸ ✮ ✪',
    h1: 'Star text symbols',
    subtitle: 'Click on a star below to copy it to your clipboard, then paste it wherever you like online.'
  },
  whitespace: {
    title: 'Whitespace Characters — Copy & Paste Invisible Characters',
    description: 'Copy and paste blank Unicode characters like the zero-width space and braille blank — and learn about when to use them.',
    h1: 'Whitespace characters',
    subtitle: 'Quickly copy and paste Unicode whitespace characters — and learn how and when to use them.'
  }
}
