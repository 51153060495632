import React from 'react'

import { FontGenerator } from '../index'

import { sans, sansBold, sansBoldItalic, sansItalic } from '../functions'

import styles from './styles.module.scss'
import { serifFontTool, underlineTextTool } from '../../tools'

const sansSerifVariants = [
  { name: 'Sans serif', fn: sans },
  { name: 'Sans serif bold', fn: sansBold },
  { name: 'Sans serif italic', fn: sansItalic },
  { name: 'Sans serif bold italic', fn: sansBoldItalic }
]

export const previewText = sansSerifVariants[0].fn

const tags = [
  { name: 'Unicode' },
  { name: 'Tools' },
  { name: 'Fonts' },
  { name: 'Hacks' },
  { name: 'Text' },
  { name: 'Meta' },
  { name: 'Italics' }
]

const SansSerif: React.FC = () => {
  return (
    <FontGenerator
      variants={sansSerifVariants}
      subtitleClassName={styles.subtitle}
      tags={tags}
      moreTools={[serifFontTool, underlineTextTool]}
    />
  )
}

export default SansSerif
