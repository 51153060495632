import React from 'react'

import { TextGroup } from '../../../components/TextGroup'

import { FontGenerator } from '../index'

import { squared, squaredBlack } from '../functions'

import styles from './styles.module.scss'
import { circledFontTool, strikethroughGeneratorTool } from '../../tools'

const squareVariants = [
  { name: 'Squared', fn: squared },
  { name: 'Negative squared', fn: squaredBlack }
]

export const previewText = squareVariants[0].fn

const tags = [
  { name: 'Unicode' },
  { name: 'Tools' },
  { name: 'Fonts' },
  { name: 'Hacks' },
  { name: 'Text' },
  { name: 'Meta' }
]

const Square: React.FC = () => {
  return (
    <FontGenerator
      variants={squareVariants}
      subtitleClassName={styles.subtitle}
      tags={tags}
      moreTools={[strikethroughGeneratorTool, circledFontTool]}
    >
      <TextGroup title='Squared / enclosed alphanumerics' style={{ marginTop: 64 }}>
        <p>
          Squared characters have a wide variety of uses.
          The first ones were added to Unicode for Japanese television stations.
          They needed symbols like 🅆 for wide format broadcasting and 🄽 for news.
        </p>
        <p>
          Later, Unicode added 🅰, 🅱, 🅾, and 🆎 for blood types, 🆏 for public toilets, and 🅿 for parking.
          These were quickly followed by the rest of the alphabet.
        </p>
        <p>
          Note: Some of these symbols render as emojis in certain applications.
        </p>
      </TextGroup>
    </FontGenerator>
  )
}

export default Square
