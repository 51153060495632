import React from 'react'

import { FontGenerator } from '../index'

import { doublestruck } from '../functions'

import { TextGroup } from '../../../components/TextGroup'

import styles from './styles.module.scss'
import { strikethroughGeneratorTool, underlineTextTool } from '../../tools'

const outlineVariants = [
  { name: 'Double struck / outlined / blackboard bold', fn: doublestruck }
]

export const previewText = outlineVariants[0].fn

const tags = [
  { name: 'Unicode' },
  { name: 'Tools' },
  { name: 'Fonts' },
  { name: 'Hacks' },
  { name: 'Text' },
  { name: 'Meta' }
]

const Outline: React.FC = () => {
  return (
    <FontGenerator
      variants={outlineVariants}
      subtitleClassName={styles.subtitle}
      tags={tags}
      moreTools={[strikethroughGeneratorTool, underlineTextTool]}
    >
      <TextGroup title='Double struck / outlined / blackboard bold' style={{ marginTop: 64 }}>
        <p>Double struck is also known as blackboard bold. It originated as an attempt to create bold lettering with chalk on a blackboard.</p>
        <p>
          The style worked its way into mathematical equations.
          Later, it was emulated on typewriters by overlaying the same character twice, leading to the name “double struck.”
        </p>
      </TextGroup>
    </FontGenerator>
  )
}

export default Outline
