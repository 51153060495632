import cn from 'classnames'
import React from 'react'
import RangeSlider from 'react-range-slider-input'

import styles from './styles.module.scss'

type Props = {
  name?: string
  value?: number
  onChange: any
  gap?: number
  nameClassName?: string
  min?: number
  max?: number
  step?: number
  className?: string
}

export const ProgressBar: React.FC<Props> = ({ className, min, max, step, nameClassName, gap = 55, name = 'Preview size', value = 0, onChange }) => {
  return (
    <div className={cn(styles.container, className)} style={{ gap }}>
      <p className={cn(styles.text, nameClassName)}>{name}</p>
      <RangeSlider
        value={[0, value]}
        onInput={([, newFontSize]: [number, number]) => onChange(newFontSize)}
        className={'single-thumb'}
        thumbsDisabled={[true, false]}
        rangeSlideDisabled={true}
        min={min}
        max={max}
        step={step}
      />
    </div>
  )
}
