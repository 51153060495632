import cn from 'classnames'
import React, { useEffect, useState } from 'react'

import { Card } from '../../../components/Card'
import { Discover } from '../../../components/Discover'
import { Dropdown } from '../../../components/Dropdown'
import { ProgressBar } from '../../../components/ProgressBar'
import { Search } from '../../../components/Search'
import { Tags } from '../../../components/Tags'
import { TextGroup } from '../../../components/TextGroup'
import { Tooltip } from '../../../components/Tooltip'
import { WhyNamecheap } from '../../../components/WhyNamecheap'
import { Header } from '../../../components/Header'

import { charsetNameOptions, fontOptions, tags } from './constants'

import { getZalgo } from './functions'

import QuestoinIcon from './assets/question.svg'

import styles from './styles.module.scss'

export const waveOptions = [
  { label: 'Sine wave', value: 'sineWave' },
  { label: 'Slope up', value: 'incremental' },
  { label: 'Slope down', value: 'incremental2' },
  { label: 'Zalgo classic™', value: 'classic' }
]

const initialFrequency = 1
const initialAmplitude = 5
const initialFont = fontOptions[0].name
const initialCharsetName = charsetNameOptions[0].name
const initialText = 'This is Zalgo Text'
const initialWave = waveOptions[0].value

export const previewText = (text?: string): string => getZalgo({
  text: text ?? initialText,
  font: initialFont,
  shape: initialWave,
  charsetName: initialCharsetName,
  initAmplitude: initialAmplitude,
  frequency: initialFrequency
})

const Zalgo: React.FC = () => {
  const [text, setText] = useState('')
  const [fontSize, setFontSize] = useState(16)
  const [charsetName, setCharsetName] = useState(initialCharsetName)
  const [font, setFont] = useState(initialFont)

  const [frequency, setFrequency] = useState(initialFrequency)
  const [initAmplitude, setAmplitude] = useState(initialAmplitude)
  const [zalgo, setZalgo] = useState('')

  const [shape, setShape] = useState(initialWave)

  useEffect(() => {
    setZalgo(getZalgo({ text: text || initialText, font, shape, charsetName, initAmplitude, frequency }))
  }, [text, font, charsetName, font, shape, initAmplitude, frequency])

  return (
    <>
      <Header>
        <Search value={text} onChange={setText} />
      </Header>

      <div className={styles.container}>
        <div className={styles['small-container']}>
          <div className={styles['preview-size']}><ProgressBar className={styles.progress} min={12} max={72} value={fontSize} onChange={setFontSize} /></div>
          <div className={styles['first-row']}>
            <Dropdown name='Zalgo shape' options={waveOptions} value={shape} onChange={setShape} />
            <Dropdown name='Characters' options={charsetNameOptions.map(i => i.name)} value={charsetName} onChange={setCharsetName} />
            <Dropdown name='Font' options={fontOptions.map(i => i.name)} value={font} onChange={setFont} />
          </div>
          <div className={styles['second-row']}>
            <ProgressBar className={styles.progress} nameClassName={styles['progress-slider-name']} gap={16} name="Frequency" value={frequency} onChange={setFrequency}
              min={0} max={1} step={0.01} />
            <ProgressBar className={styles.progress} nameClassName={styles['progress-slider-name']} gap={16} name="Amplitude" value={initAmplitude} onChange={setAmplitude} />
            <Tooltip
              timeout={false}
              text={
                <>
                  <p><b>Frequency</b> controls the shape of the <i>sine wave</i> shape.</p>
                  <p><b>Amplitude</b> controls the height of the glitch.</p>
                </>
              }
              showOnClick
            >
              <div className={styles['question-circle']}><QuestoinIcon /></div>
            </Tooltip>
          </div>
        </div>
      </div>

      <div className={styles['variants-container']}>
        <Card className={styles.card} textClassName={cn(styles['card-text'], styles.mono)} text={zalgo} title='Zalgo text' textFontSize={fontSize} />
      </div>
      <TextGroup title='How does Zalgo text work?' style={{ marginTop: 64 }}>
        <p>Zalgo text uses combining diacritical marks stacked on top of one another to create a creepy, zalgo effect.</p>
        <p>Diacritical marks are symbols added to letters. They’re primarily used to describe how a letter should be pronounced.</p>
        <p>In English, diacritical marks appear most often in words borrowed from other languages:</p>
        <ul>
          <li>Accents in words like résumé, fiancée, and Pokémon.</li>
          <li>Tildes in words like jalapeño, piña colada, and El Niño.</li>
          <li>Diaereses in words like naïve, Noël, and sometimes coöperate.</li>
        </ul>
        <p>
          There are more possible combinations of diacritical marks and letters than atoms in the observable universe.
          So, it’s safe to say it would be impractical to come up with a separate character for each combination.
        </p>
        <p>
          Instead, Unicode — the standardized system for computer characters — allows us to combine these marks and stack them on top of one another.
          This is how Zalgo text is created.
        </p>
      </TextGroup>
      <TextGroup title='Who is Zalgo? 👹' style={{ marginTop: 48 }}>
        <p>Zalgo, like Slenderman and Momo, was an internet bogeyman. Just not a particularly scary one.</p>
        <p>Zalgo first showed up in subversive comic strips, then as a demonic clown child or a tentacled demon.</p>
        <p>Most importantly, Zalgo would be accompanied by creepy, zalgo text, which is where the name comes from.</p>
      </TextGroup>
      <TextGroup title='Where can you use Zalgo text?' style={{ marginTop: 48 }}>
        You can Zalgo text almost anywhere on the internet, though it renders differently on various websites.
        In some text inputs, such as Instagram bios, the diacritical marks overflow the box.
      </TextGroup>
      <Tags options={tags} />
      <Discover />
      <WhyNamecheap />
    </>
  )
}

export default Zalgo
