import React, { useState } from 'react'
import cn from 'classnames'

import { Card } from '../../components/Card'
import { ProgressBar } from '../../components/ProgressBar'
import { Search } from '../../components/Search'
import { Button } from '../../components/Button'
import { Tags } from '../../components/Tags'
import type { Tag } from '../../components/Tags'
import { Discover } from '../../components/Discover'
import { WhyNamecheap } from '../../components/WhyNamecheap'

import { MoreTools } from '../../components/MoreTools'
import type { Tool } from '../../components/MoreTools'

import styles from './styles.module.scss'
import { Header } from '../../components/Header'

type Variant = {
  key?: string
  name: string
  fn: (text?: string, underline?: string) => string
  className?: string
}

type Props = {
  variants: Variant[]
  showUnderline?: boolean
  tags?: Tag[]
  moreTools?: Tool[]
  children?: React.ReactNode
  subtitleClassName?: string
}

export const FontGenerator: React.FC<Props> = ({ subtitleClassName, variants, showUnderline = false, tags = [], moreTools, children }) => {
  const [text, setText] = useState<string>()
  const [fontSize, setFontSize] = useState(16)
  const [underline, setUnderline] = useState('')

  const addUnderline = (): void => setUnderline(underline ? '' : '\u200B\u0332')

  return (
      <div className={styles.container}>
        <Header headerClassName={styles['header-image']} className={styles['header-container']} subtitleClassName={cn(subtitleClassName, styles.subtitle)}>
          <Search value={text} onChange={setText} />
        </Header>
        <div className={styles['small-container']}>
          {
            showUnderline
              ? (
                  <div className={styles.row}>
                    <Button onClick={addUnderline}>{underline ? 'Remove underline' : 'Add underline'}</Button>
                    <div className={styles['progress-bar']}><ProgressBar value={fontSize} onChange={setFontSize} /></div>
                  </div>
                )
              : null
          }
          {showUnderline ? null : <div className={styles['progress-bar']}><ProgressBar min={12} max={72} value={fontSize} onChange={setFontSize} /></div>}
        </div>
        <div className={styles['small-container']}>
          <div className={styles['variants-container']}>
            {variants.map(variant => (
              <Card className={cn(styles.card, variant.className)} key={variant.key || variant.name} text={variant.fn(text, underline) || variant.fn()} title={variant.name} textFontSize={fontSize} />
            ))}
          </div>
        </div>
        {children}
        {moreTools ? <MoreTools tools={moreTools} /> : null}
        <Tags options={tags} />
        <Discover />
        <WhyNamecheap />
      </div>
  )
}
