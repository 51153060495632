import React from 'react'

import { Card } from './components/Card'

import styles from './styles.module.scss'

export const copyAndPasteTools = [
  { name: 'Calendar generator', count: 5, icon: '📅', link: 'calendar/' },
  { name: 'Emoji horoscope', count: 1, icon: '♑️' },
  { name: 'Checkmarks', count: 12, icon: '✅' },
  { name: 'Chess symbols', count: 'Multiple', icon: '♟️' },
  { name: 'Interrobang', count: 9, icon: '‽' },
  { name: 'Snowflake symbols', count: 'Multiple', icon: '❄️', link: 'snowflake-text-symbols/' },
  { name: 'Star symbols', count: 'Multiple', icon: '⭐' },
  { name: 'Whitespace characters', count: 12, icon: '_', link: 'whitespace/', iconColor: '#A3A3A3' }
]

function subtitle (count: number | string): string {
  if (typeof count === 'number') {
    return `${count} ${count > 1 ? 'variations' : 'variation'}`
  }
  return count
}

export const CopyAndPasteTab: React.FC = () => {
  return (
    <div className={styles['fonts-container']}>
      {copyAndPasteTools.map(variant => (
        <Card
          key={variant.name}
          title={variant.name}
          subtitle={subtitle(variant.count)}
          icon={variant.icon}
          link={variant.link ?? `/${variant.name.toLowerCase().replace(/\s/g, '-')}/`}
          iconColor={variant.iconColor}
        />
      ))}
    </div>
  )
}
