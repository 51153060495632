import { charsetNameOptions, fontOptions } from './constants'

type WaveFnOptions = {
  charsetName: string
  initAmplitude: number
  frequency: number
  amount: number
  length: number
}

type ZalgoFnOptions = {
  text: string
  font: string
  shape: string
  charsetName: string
  initAmplitude: number
  frequency: number
}

const getRandomNumber = (min: number, max: number): number => {
  return Math.random() * (max - min) + min
}

const classic = ({ charsetName, initAmplitude }: WaveFnOptions): string => {
  const set = charsetNameOptions.find(i => i.name === charsetName)!.set

  let i
  let zalgoString = ''
  let num = 0
  let amplitude = initAmplitude
  if (amplitude > 200) {
    amplitude = 200
  }
  if (amplitude < -200) {
    amplitude = -200
  }

  for (i = 0; i < amplitude; i++) {
    num = getRandomNumber(0, set.length)
    zalgoString += set[Math.floor(num)]
  }

  return zalgoString
}

const incremental = ({ charsetName, initAmplitude, amount }: WaveFnOptions): string => {
  const set = charsetNameOptions.find(i => i.name === charsetName)!.set

  let i
  let zalgoString = ''
  let num = 0
  let amplitude = initAmplitude

  if (amplitude > 200) {
    amplitude = 200
  }

  if (amplitude < -200) {
    amplitude = -200
  }

  const incrementalAmount = (amount * amplitude) / 5

  for (i = 0; i < incrementalAmount; i++) {
    num = getRandomNumber(0, set.length)
    zalgoString += set[Math.floor(num)]
  }

  return zalgoString
}

const incremental2 = ({ charsetName, initAmplitude, amount, length }: WaveFnOptions): string => {
  const set = charsetNameOptions.find(i => i.name === charsetName)!.set

  let i
  let zalgoString = ''
  let num = 0
  let amplitude = initAmplitude

  if (amplitude > 200) {
    amplitude = 200
  }
  if (amplitude < -200) {
    amplitude = -200
  }

  const incrementalAmount = ((length - amount) * amplitude) / 5

  for (i = 0; i < incrementalAmount; i++) {
    num = getRandomNumber(0, set.length)
    zalgoString += set[Math.floor(num)]
  }
  return zalgoString
}

const sineWave = ({ charsetName, initAmplitude, frequency, amount, length }: WaveFnOptions): string => {
  const set = charsetNameOptions.find(i => i.name === charsetName)!.set

  let i
  let zalgoString = ''
  let num = 0
  let amplitude = initAmplitude

  if (amplitude > 200) {
    amplitude = 200
  }
  if (amplitude < -200) {
    amplitude = -200
  }

  const sineAmount =
  (amplitude / 10) *
  Math.sin((((amount * 1) / length) * Math.PI) / frequency) *
  length

  for (i = 0; i < sineAmount; i++) {
    num = getRandomNumber(0, set.length)
    zalgoString += set[Math.floor(num)]
  }
  return zalgoString
}

const waveFunctions: Record<string, (args: WaveFnOptions) => string> = {
  classic,
  incremental,
  incremental2,
  sineWave
}

export const getZalgo = ({ text, font, shape, charsetName, initAmplitude, frequency }: ZalgoFnOptions): string => {
  let newArray
  const fontMap = fontOptions.find(i => i.name === font)!
  if (fontMap.value) {
    newArray = text.split('').map(e => fontMap.value[e] || e)
  } else {
    newArray = text.split('').map(e => e)
  }

  const length = newArray.length
  let newString = ''

  newArray.forEach((char, i) => {
    char += waveFunctions[shape]({ charsetName, initAmplitude, frequency, amount: i + 1, length })
    newString += char
  })

  return newString
}
