import { smallCapsDict } from './constants'

export const smallCaps = (text: string = 'Sᴍᴀʟʟ Cᴀᴘs', underline: string = ''): string => {
  return (
    underline +
    text
      .split('')
      .map(e => smallCapsDict[e] || e)
      .join(underline) + underline
  )
}
