import React from 'react'

import { TextGroup } from '../../../components/TextGroup'

import { FontGenerator } from '../index'

import {
  doubleStruckSpongecase, doubleStruckSpongecaseAlternate,
  monospacedSpongecase, monospacedSpongecaseAlternate,
  spongecase, spongecaseAlternate
} from './functions'

import styles from './styles.module.scss'

const spongecaseVariants = [
  { name: 'Default spongecase', fn: spongecase },
  { name: 'Default spongecase alternate', fn: spongecaseAlternate },
  { name: 'Monospaced spongecase', fn: monospacedSpongecase },
  { name: 'Monospaced spongecase alternate', fn: monospacedSpongecaseAlternate },
  { name: 'Double-struck spongecase', fn: doubleStruckSpongecase },
  { name: 'Double-struck spongecase alternate', fn: doubleStruckSpongecaseAlternate },
  { name: 'Spongebob-related emojis', fn: (() => '🐿️ 🐌 🍍 🦀 🦑 🧽 🏴‍☠️') as any }
]

export const previewText = spongecaseVariants[0].fn

const tags = [
  { name: 'Unicode' },
  { name: 'Tools' },
  { name: 'Fonts' },
  { name: 'Hacks' },
  { name: 'Text' },
  { name: 'Meta' },
  { name: 'Memes' },
  { name: 'Emoji' }
]

const Spongebob: React.FC = () => {
  return (
    <FontGenerator
      variants={spongecaseVariants}
      subtitleClassName={styles.subtitle}
      tags={tags}
    >
      <TextGroup title='Why should I use mocking SpongeBob text?' style={{ marginTop: 64 }}>
        Use this tool to quickly generate mocking SpongeBob text without having to press shift.
        The result features alternating uppercase and lowercase letters, which is popular for memes, social media posts, and more.
      </TextGroup>
    </FontGenerator>
  )
}

export default Spongebob
