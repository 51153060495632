import React from 'react'

import { Table } from '../../../components/Table'
import { TextGroup } from '../../../components/TextGroup'

import { FontGenerator } from '../index'

import { backwards, backwardsWordsOnly, mirrored, mirroredLettersOnly, mirroredWordsAndLetters } from './functions'

import { table, table2, tags } from './constants'

import styles from './styles.module.scss'

const backwardsVariants = [
  { name: 'Backwards text', fn: backwards },
  { name: 'Mirrored text', fn: mirroredLettersOnly },
  { name: 'Backwards words only', fn: backwardsWordsOnly },
  { name: 'Mirrored letters only', fn: mirrored },
  { name: 'Mirrored words and letters', fn: mirroredWordsAndLetters }
]

export const previewText = backwardsVariants[0].fn

const Backwards: React.FC = () => {
  return (
    <FontGenerator
      variants={backwardsVariants}
      subtitleClassName={styles.subtitle}
      tags={tags}
    >
      <TextGroup title='How does the backwards text generator work?' style={{ marginTop: 64 }}>
        Turning text backwards is simple. Using a small bit of code, the order of the letters you type is automatically reversed.
      </TextGroup>
      <TextGroup title='How does the mirrored text generator work?' style={{ marginTop: 48 }}>
        <p>
          Unicode is the standard encoding for all computer characters.
          It is a global agreement on how our devices render the symbols for all languages, math, and even emojis.
        </p>
        <p>
          The mirrored text generator replaces the Roman alphabet with Unicode symbols that look like the reverse of the Roman characters.
        </p>
      </TextGroup>
      <TextGroup title='Where do the mirrored Unicode characters come from?' style={{ marginTop: 48 }}>
        <p>There are no Unicode characters defined for mirrored text. But most letters have a similar backwards version used in a different writing system.</p>
        <p>
          Some Roman characters are symmetrical and already look the same backwards.
          These stay the same. Many of the other letters are swapped with symbols from the International Phonetic Alphabet (IPA).
          The IPA is a set of symbols used by linguists to describe the exact sounds of spoken languages.
        </p>
        <p>Use the table below to see which letters the tool swaps in to create a mirrored effect.</p>
      </TextGroup>
      <Table className={styles.table} title='Origin of replacement' style={{ marginTop: 64 }} columnsWidth='55px 55px 9fr'>
        {table}
      </Table>
      <Table className={styles.table} style={{ marginTop: 64 }} columnsWidth='55px 55px 9fr'>
        {table2}
      </Table>
    </FontGenerator>
  )
}

export default Backwards
