import React from 'react'

import styles from './styles.module.scss'

type Props = {
  children: React.ReactNode
  style?: React.CSSProperties
  container?: boolean
  autoWith?: boolean
}

export const TextTile: React.FC<Props> = ({ children, style, container = true, autoWith = false }) => {
  const tile = (
    <div className={styles.tile} style={{ ...style, width: autoWith ? 'auto' : undefined }}>
      {children}
    </div>
  )
  if (!container) {
    return tile
  }
  return (
    <div className={styles.container}>
      {tile}
    </div>
  )
}
