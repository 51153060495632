import React from 'react'
import { Link } from 'react-router-dom'

import styles from './styles.module.scss'

export type Tag = {
  name: string
  link?: string
}

type Props = {
  options: Tag[]
}

export const Tags: React.FC<Props> = ({ options }) => {
  return (
    <div className={styles.container}>
      <ul className={styles.list}>
        {options.map(option => {
          const link = `/tag/${option.link ?? option.name.toLowerCase().replace(/\s/g, '-')}/`

          return (
            <li className={styles['option-container']} key={option.name}>
              <Link className={styles.option} key={option.name} to={link}>
                {option.name}
              </Link>
            </li>
          )
        })}
      </ul>
    </div>
  )
}
