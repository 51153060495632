import React from 'react'

import { FontGenerator } from '../index'

import { serifBold, serifBoldItalic } from '../functions'

import styles from './styles.module.scss'
import { sansSerifFontTool, underlineTextTool } from '../../tools'

const serifVariants = [
  { name: 'Serif bold', fn: serifBold },
  { name: 'Serif bold italic', fn: serifBoldItalic }
]

export const previewText = serifVariants[0].fn

const tags = [
  { name: 'Unicode' },
  { name: 'Tools' },
  { name: 'Fonts' },
  { name: 'Hacks' },
  { name: 'Text' },
  { name: 'Meta' },
  { name: 'Italics' }
]

const Serif: React.FC = () => {
  return (
    <FontGenerator
      variants={serifVariants}
      subtitleClassName={styles.subtitle}
      tags={tags}
      moreTools={[sansSerifFontTool, underlineTextTool]}
    />
  )
}

export default Serif
