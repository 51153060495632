import React from 'react'
import cn from 'classnames'

import styles from './styles.module.scss'

type Props = {
  disabled?: boolean
  onClick: (e: React.SyntheticEvent) => void
  children: React.ReactNode
  secondary?: boolean
  className?: string
}

export const Button: React.FC<Props> = ({ children, onClick, secondary = false, className, disabled = false }) => {
  return (
    <button disabled={disabled} className={cn(className, styles.container, { [styles.secondary]: secondary })} onClick={onClick}>
      {children}
    </button>
  )
}
