import type { Meta } from './types'

export const fontGeneratorsMeta: Record<string, Meta> = {
  'backwards-text': {
    title: 'Backwards Text Generator | Copy and Paste Reversed or Mirrored Text',
    description: 'Generate backwards or bɘɿoɿɿim text to copy and paste in social media, instant messages, emails, and pretty much anywhere else online.',
    h1: 'Backwards text generator',
    subtitle: 'Generate reversed or mirrored text to copy and paste just about anywhere online, including social media posts, bios, instant messages, and browser tabs.'
  },
  'vaporwave-text': {
    title: 'Vaporwave【Ａｅｓｔｈｅｔｉｃ】Text Generator — Vaporwave Fonts',
    description: 'Generate full-width text that works in social media posts, bios, instant messages, browser tabs, and more. ＶＡＰＯＲＷＡＶＥ ＩＳ ＤＥＡＤ',
    h1: 'Vaporwave text generator',
    subtitle: 'Generate ＦＵＬＬ ＷＩＤＴＨ text to copy and paste in social media posts, bios, instant messages, browser tabs, and more.'
  },
  spongebob: {
    title: 'MoCkInG SpOnGeBoB Meme Text Generator',
    description: 'Type your text below to generate the mOcKiNg sPoNgEbOb version, then click to copy the results.',
    h1: 'Spongebob text generator',
    subtitle: 'Type your message below to generate the mocking SpongeBob version, then click to copy and paste the results.'
  },
  'upside-down-text': {
    title: 'Upside Down Text Generator — uʍop ǝpᴉsdn Flipping Text to Copy & Paste',
    description: 'Generate uʍop ǝpᴉsdn text you can use anywhere online. Add upside down text to social media bios, instant messages, emails, and more.',
    h1: 'Upside down text generator',
    subtitle: 'Create upside down text you can copy and paste pretty much everywhere online, including social media bios, instant messages, emails, and more.'
  },
  'zalgo-text': {
    title: 'Zalgo Text Generator — Copy and Paste Zalgo Text',
    description: 'Copy and paste Zalgo text you can use anywhere online. 𝙶ͦͥͮͮͥͥͧ𝚎ͮͦͫͥͨͩ𝚗ͬͥͤͭͤ𝚎ͦͭͤͮ𝚛ͧͥͬ𝚊ͪͧ𝚝ͥ𝚎 uniquely cursed text in different s̳̄ẖ̠͟ā̠̄p̱̿͟e̠̠̱s̲͞.',
    h1: 'Zalgo text generator',
    subtitle: 'A tool to create then copy and paste creepy, Z̽̂a͔ͮ̑ĺ̹̈́g̪̿ö́ text.'
  },
  cursive: {
    title: 'Cursive Text Generator | 𝓒𝓸𝓹𝔂 𝒶𝓃𝒹 𝕻𝖆𝖘𝖙𝖊 Fancy 𝓒𝓾𝓻𝓼𝓲𝓿𝓮 Text',
    description: 'Generate text in a 𝓒𝓾𝓻𝓼𝓲𝓿𝓮 font then copy and paste it anywhere online, including Instagram bios, Facebook posts, Reddit comments, and lots more.',
    h1: 'Cursive text generator',
    subtitle: 'Generate 𝒞𝓊𝓇𝓈𝒾𝓋ℯ "handwriting" to copy and paste in Facebook posts, Instagram bios, instant messages, and lots of other places online.'
  },
  superscript: {
    title: 'Superscript Generator ᵃʷᵉˢᵒᵐᵉꜝ | Copy and Paste Tiny Text',
    description: 'Use superscript everywhere online — including social media, emails, browser tabs, and more.',
    h1: 'Superscript generator',
    subtitle: 'Copy and paste superscript to social media⁽ᵃʷᵉˢᵒᵐᵉ⁾, instant messages⁽ʷᵒʷ⁾, emails⁽ᵒᵏ⁾, and more.'
  },
  'bold-text': {
    title: 'Bold Text Generator | 𝕮𝖔𝖕𝖞 𝐚𝐧𝐝 𝗣𝗮𝘀𝘁𝗲 𝘽𝙤𝙡𝙙 𝙁𝙤𝙣𝙩',
    description: 'Copy and paste bold fonts (and italic bold text) for your social media posts, bios, instant messages, and more.',
    h1: 'Bold text generator',
    subtitle: 'Generate bold text to copy and paste anywhere online, including social media posts, bios, instant messages, and browser tabs.'
  },
  italic: {
    title: 'Italics Generator | 𝑪𝒐𝒑𝒚 𝒶𝓃𝒹 𝙋𝙖𝙨𝙩𝙚 Italic Underline Text',
    description: 'Generate italicized text you can copy and paste into Facebook posts, Instagram bios, instant messages, and 𝙡𝙤𝙩𝙨 𝙢𝙤𝙧𝙚.',
    h1: 'Italic text generator',
    subtitle: "Copy and paste italics in places you normally can't, like social media posts and bios, instant messages, and more."
  },
  'small-caps': {
    title: 'Sᴍᴀʟʟ Cᴀᴘs Font Generator | Tiny Text Font Effect',
    description: 'Quickly generate, copy, and paste Unicode Sᴍᴀʟʟ Cᴀᴘs. Get a quick guide to Sᴍᴀʟʟ Cᴀᴘs, including what they are and when to use them.',
    h1: 'Small caps font generator',
    subtitle: 'Generate sᴍᴀʟʟ ᴄᴀᴘs to copy and paste pretty much everywhere online.'
  },
  strikethrough: {
    title: 'Strikethrough Text Generator - 𝚂̶𝚝̶𝚛̶𝚒̶𝚔̶𝚎̶𝚝̶𝚑̶𝚛̶𝚘̶𝚞̶𝚐̶𝚑̶ Font to Copy & Paste',
    description: 'Create strikethrough, 𝚜̷𝚕̷𝚊̷𝚜̷𝚑̷𝚎̷𝚍̷, or redacted text that works anywhere online, including social media posts and bios, emails, and more.',
    h1: 'Strikethrough text generator',
    subtitle: 'Create strikethrough text to copy and paste in social media posts and bios, emails, and pretty much anywhere else online.'
  },
  'underline-text': {
    title: '𝙐̲𝙣̲𝙙̲𝙚̲𝙧̲𝙡̲𝙞̲𝙣̲𝙚̲ Generator | Create, Copy, and Paste Underlined Text',
    description: "Use underlined text in social media and other places you're 𝚗̲𝚘̲𝚝̲ ̲𝚗̲𝚘̲𝚛̲𝚖̲𝚊̲𝚕̲𝚕̲𝚢̲ ̲𝚊̲𝚕̲𝚕̲𝚘̲𝚠̲𝚎̲𝚍̲.",
    h1: 'Underline generator',
    subtitle: 'Create underlined text you can copy and paste for social media, browser bookmarks, and other places where it usually isn’t possible.'
  },
  fancy: {
    title: 'Fancy Font Generator — 𝓬𝓸𝓹𝔂 𝕒𝕟𝕕 𝓹𝓪𝓼𝓽𝓮 Fonts Online',
    description: 'Quickly copy and paste 𝕱𝖆𝖓𝖈𝖞, eye-catching text that works anywhere online. Use cool fonts including 𝕱𝖗𝖆𝖐𝖙𝖚𝖗, 𝔻𝕠𝕦𝕓𝕝𝕖 𝕊𝕥𝕣𝕦𝕔𝕜, 𝒞𝓊𝓇𝓈𝒾𝓋ℯ, 𝚖𝚘𝚗𝚘𝚜𝚙𝚊𝚌𝚎, 🅒🅘🅡🅒🅛🅔🅓, and lots more.',
    h1: 'Fancy font generator',
    subtitle: 'Quickly create fancy, eye-catching text that works in social media posts, bios, messages, and more!'
  },
  gothic: {
    title: 'Gothic Font Generator - ℭ𝔬𝔭𝔶 & 𝔓𝔞𝔰𝔱𝔢 Gothic Fonts',
    description: 'Create 𝔊𝔬𝔱𝔥𝔦𝔠 fonts with our Gothic font generator. Copy and paste 𝔊𝔬𝔱𝔥𝔦𝔠 text for your projects effortlessly.',
    h1: 'Gothic font generator',
    subtitle: 'Generate classic 𝔊𝔬𝔱𝔥𝔦𝔠 text to copy and paste in Facebook posts, Instagram bios, instant messages, emails, and more.'
  },
  square: {
    title: 'Square Font Generator | 🅲🅾🅿🆈 & 🅿🅰🆂🆃🅴 Squared Fonts',
    description: 'Design stylish 🅂🅀🅄🄰🅁🄴🄳 text with our square font generator. 🅲🅾🅿🆈 and 🅿🅰🆂🆃🅴 squared fonts for your personal or business needs.',
    h1: 'Square font generator',
    subtitle: 'Copy and paste 🅂🅀🅄🄰🅁🄴🄳 text you can use anywhere online, including instant messages, social media, emails, and more.'
  },
  circled: {
    title: 'Circled Font Generator - 🅒🅞🅟🅨 & 🅟🅐🅢🅣🅔 Circled Text',
    description: 'Generate ⓒⓘⓡⓒⓛⓔⓓ text with our circled font generator. Easily create 🅒🅘🅡🅒🅛🅔🅓 text for unique designs.',
    h1: 'Circled font generator',
    subtitle: 'Copy and paste 🅒🅘🅡🅒🅛🅔🅓 text you can use anywhere online, including instant messages, social media, emails, and more.'
  },
  typewriter: {
    title: 'Typewriter Font Generator - 𝙲𝚘𝚙𝚢 & 𝙿𝚊𝚜𝚝𝚎 Typewriter Fonts',
    description: 'Get that classic 𝚝𝚢𝚙𝚎𝚠𝚛𝚒𝚝𝚎𝚛 look with our typewriter font generator. 𝙲𝚘𝚙𝚢 𝚊𝚗𝚍 𝚙𝚊𝚜𝚝𝚎 𝚢𝚘𝚞𝚛 𝚝𝚎𝚡𝚝 in your own style.',
    h1: 'Typewriter font generator',
    subtitle: 'Сopy and paste classic 𝚝𝚢𝚙𝚎𝚠𝚛𝚒𝚝𝚎𝚛 text for Facebook posts, Instagram bios, instant messages, emails, and more.'
  },
  outline: {
    title: 'Outline Font Generator - ℂ𝕠𝕡𝕪 & ℙ𝕒𝕤𝕥𝕖 Outlined Fonts',
    description: 'Create 𝕠𝕦𝕥𝕝𝕚𝕟𝕖𝕕 fonts with our outline font generator. Make your own 𝕥𝕖𝕩𝕥 𝕨𝕚𝕥𝕙 𝕠𝕦𝕥𝕝𝕚𝕟𝕖𝕤 for your creative projects.',
    h1: 'Outline font generator',
    subtitle: 'Create 𝕠𝕦𝕥𝕝𝕚𝕟𝕖𝕕 text to copy and paste in Facebook posts, Instagram bios, instant messages, emails, and more.'
  },
  'sans-serif': {
    title: 'Sans Serif Font Generator - 𝖢𝗈𝗉𝗒 & 𝖯𝖺𝗌𝗍𝖾 Sans Serif Fonts',
    description: 'Make clean and modern text design with our sans serif font generator. Easily 𝖼𝗈𝗉𝗒 𝖺𝗇𝖽 𝗉𝖺𝗌𝗍𝖾 𝗌𝖺𝗇𝗌 𝗌𝖾𝗋𝗂𝖿 fonts for your projects.',
    h1: 'Sans serif font generator',
    subtitle: 'Create modern 𝗌𝖺𝗇𝗌 𝗌𝖾𝗋𝗂𝖿 text that can be copied and pasted almost anywhere online, including social media, emails, and more.'
  },
  serif: {
    title: 'Serif Font Generator - 𝐂𝐨𝐩𝐲 & 𝐏𝐚𝐬𝐭𝐞 Serif Fonts',
    description: 'Elevate your designs with 𝐬𝐞𝐫𝐢𝐟 fonts from our Namecheap serif font generator. 𝐂𝐨𝐩𝐲 𝐚𝐧𝐝 𝐩𝐚𝐬𝐭𝐞 𝐬𝐞𝐫𝐢𝐟 fonts for elegant typography.',
    h1: 'Serif font generator',
    subtitle: 'Create traditional 𝐬𝐞𝐫𝐢𝐟 text that can be copied and pasted almost anywhere online, including social media, emails, and more.'
  }
}
