import React from 'react'

import { TextGroup } from '../../../components/TextGroup'

import { FontGenerator } from '../index'

import { mono, monoSlash, redacted, sansSerif, sansSerifSlash, unstyled, unstyledSlash } from './functions'

import type { Tool } from '../../../components/MoreTools'

import styles from './styles.module.scss'
import { italicTextTool, underlineTextTool } from '../../tools'

const strikethroughVariants = [
  { name: 'Monospace with strikethrough', fn: mono },
  { name: 'Monospace with slashes', fn: monoSlash },
  { name: 'Sans serif with strikethrough', fn: sansSerif },
  { name: 'Sans serif with slashes', fn: sansSerifSlash },
  { name: 'Redacted', fn: redacted },
  { name: 'Unstyled with strikethrough', fn: unstyled },
  { name: 'Unstyled with slashes', fn: unstyledSlash }
]

export const previewText = strikethroughVariants[0].fn

const tags = [
  { name: 'Unicode' },
  { name: 'Tools' },
  { name: 'Fonts' },
  { name: 'Hacks' },
  { name: 'Text' },
  { name: 'Meta' }
]

const Strikethrough: React.FC = () => {
  return (
    <FontGenerator
      variants={strikethroughVariants}
      subtitleClassName={styles.subtitle}
      tags={tags}
      moreTools={[underlineTextTool, italicTextTool]}
    >
      <TextGroup title='How does this tool work?' style={{ marginTop: 64 }}>
        <p>This strikethrough text generator works by combining two diacritical marks used in accented letters like 𝗈̶  and 𝖼̷  to every character you type.</p>
        <p>To create the impression that your text has been redacted, each character is replaced with a solid block like this: █</p>
        <p>The characters used in this tool maintain their style across platforms.</p>
      </TextGroup>
      <TextGroup title='When should I use strikethrough text?' style={{ marginTop: 48 }}>
        <p>
          Strikethrough text has <s>only one use</s> two main uses:
          to make it clear that an edit has been made — while still making the previous edit clear — and to cross items off a list.
        </p>
        <p><s>Wake up</s></p>
        <p><s>Brush teeth</s></p>
        <p><s>Coffee</s></p>
        <p>Walk dog</p>
        <p>Create a Unicode tool</p>
      </TextGroup>
    </FontGenerator>
  )
}

export default Strikethrough
