import React from 'react'

import { TextGroup } from '../../../components/TextGroup'
import { TextTile } from '../../../components/TextTile'
import { TextWithImage } from '../../../components/TextWithImage'

import { FontGenerator } from '../index'

import {
  doubleUnderline,
  hyperbole,
  mono, monoArrow, monoDouble,
  silly, sillyWithHands,
  underlined,
  underlinedArrow,
  unstyled, unstyledWithoutFix
} from './functions'

import { tags } from './constants'

import type { Tool } from '../../../components/MoreTools'

import UnderlineImg from './assets/underline.svg'

import styles from './styles.module.scss'
import { strikethroughGeneratorTool, upsideDownTextTool } from '../../tools'

const underlineVariants = [
  { name: 'Monospaced and underlined', fn: mono },
  { name: 'Monospaced and underlined with arrow', fn: monoArrow },
  { name: 'Monospaced and double underlined', fn: monoDouble, className: styles.double },
  { name: 'Sans serif and underlined', fn: underlined },
  { name: 'Sans serif and double underlined', fn: doubleUnderline, className: styles.double },
  { name: 'Sans serif and underlined with arrow', fn: underlinedArrow },
  { name: 'Bold, italic, and underlined', fn: hyperbole },
  { name: 'Bold, italic, and double underlined', fn: silly, className: styles.double },
  { name: 'Bold, italic, and double underlined with manicules', fn: sillyWithHands, className: styles.double },
  { name: 'Unstyled text with stabilized underline', fn: unstyled },
  { name: 'Unstyled text without stabilized underline', fn: unstyledWithoutFix }
]

export const previewText = underlineVariants[0].fn

const Underline: React.FC = () => {
  return (
    <FontGenerator
      variants={underlineVariants}
      subtitleClassName={styles.subtitle}
      tags={tags}
      moreTools={[upsideDownTextTool, strikethroughGeneratorTool]}
    >
      <TextGroup title='How does this work?' style={{ marginTop: 64 }}>
        <p>Using <b>combining diacritical marks</b>.</p>
        <p>Diacritical marks are added to letters to describe how they should be pronounced. Accents like the one above é are diacritical marks.</p>
        <p>
          There are hundreds of thousands of possible combinations of letters and diacritical marks.
          Unicode — the standardized system of computer characters — allows us to combine diacritical marks with any character.
        </p>
        <p>By adding a “double macron below” — which looks like an underscore — to every character, we can emulate an underline.</p>
        <p>
          This workaround makes it possible to create underlined text where it’s normally not possible.
          It’s actually quite similar to the way underlined text was formatted on typewriters:
        </p>
      </TextGroup>
      <TextTile style={{ marginTop: 32, lineHeight: '150%', display: 'flex', flexDirection: 'column', gap: 32 }}>
        <b>Matthew Butterick — <a href='https://practicaltypography.com/bold-or-italic.html'>Butterick's Practical Typography</a></b>
        <p>
          Underlining is another dreary typewriter habit.
          Typewriters had no bold or italic styling.
          So the only way to emphasize text was to back up the carriage and type underscores beneath the text.
          It was a workaround for shortcomings in typewriter technology.
        </p>
      </TextTile>
      <TextWithImage
        title='Where can I use this tool?'
        image={<UnderlineImg />}
        style={{ marginTop: 64 }}
      >
        <>
          <p>You can use this underlining hack anywhere you wouldn’t usually be able to underline text.</p>
          <p>
            The characters included in the tool come from the Unicode block of mathematical alphanumeric symbols.
            They keep their style wherever you use them, which means the underlined text stays the same no matter where you paste it. 𝚕̲𝚒̲𝚔̲𝚎̲ ̲𝚝̲𝚑̲𝚒̲𝚜̲.
          </p>
          <p>
            If you use this trick without Unicode characters, you might find that it looks as though the underline is uneven — 𝗅̲𝗂̲𝗄̲𝖾̲ ̲𝗍̲𝗁̲𝗂̲𝗌̲.
          </p>
          <p>
            Social media sites have different ways of rendering diacritical marks. We’ve done our best to normalize these across all platforms.
            <b>The double underline doesn’t work well on X.</b>
          </p>
        </>
      </TextWithImage>
      <TextGroup title='When should I underline?' style={{ marginTop: 112, marginBottom: 64 }}>
        <p><b>Rarely.</b></p>
        <p>Underlined text is harder to read online. Even the convention of underlining links is disappearing.</p>
        <p>The only time it’s permissible to underline text is when you want to apply emphasis to a specific word or phrase or present text in an eye-catching way.</p>
        <p>Font Maker’s stylized text generators make it possible to create underlined text that’s easier to read and appears consistent across different platforms.</p>
      </TextGroup>
    </FontGenerator>
  )
}

export default Underline
