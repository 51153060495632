import React from 'react'

import { TextGroup } from '../../../components/TextGroup'
import { TextWithImage } from '../../../components/TextWithImage'
import { Title } from '../../../components/Title'

import { FontGenerator } from '../index'

import { tags } from './constants'

import { aesthetic, aesthetic2, aesthetic3 } from './functions'

import VaporwaveImg from './assets/vaporwave.svg'

import styles from './styles.module.scss'
import { strikethroughGeneratorTool, zalgoTool } from '../../tools'

const vaporwaveVariants = [
  { name: 'Vaporwave aesthetic', fn: aesthetic, key: 'vaporwave-1' },
  { name: 'Vaporwave aesthetic', fn: aesthetic2, key: 'vaporwave-2' },
  { name: 'Vaporwave aesthetic', fn: aesthetic3, key: 'vaporwave-3' }
]

export const previewText = vaporwaveVariants[0].fn

const Vaporwave: React.FC = () => {
  return (
    <FontGenerator
      variants={vaporwaveVariants}
      tags={tags}
      moreTools={[strikethroughGeneratorTool, zalgoTool]}
      subtitleClassName={styles.subtitle}
    >
      <TextWithImage
        title='What is vaporwave?'
        image={<VaporwaveImg />}
        style={{ marginTop: 64 }}
      >
        <>
          <p>Vaporwave is a genre of music that began entirely online. </p>
          <p>
            A typical vaporwave track consists of stretched loops, usually borrowed from elevator music or a nostalgic pop song from the ‘80s or ‘90s.
            There’s usually a surreal, dream-like quality to vaporwave music as well as a tongue-in-cheek sense of humor.
          </p>
        </>
      </TextWithImage>
      <TextGroup title='Vaporwave aesthetic' style={{ marginTop: 64 }}>
        Vaporwave is visually accompanied by images of manufactured nostalgia — often with objects like dolphins, statues, and palm trees floating in an abstract plane.
        Imagine Club Tropicana rendered on a Sega Genesis.
      </TextGroup>
      <Title center size={3} style={{ marginTop: 48 }}>
        ░▒▓ ＡＥＳＴＨＥＴＩＣ　ＴＥＸＴ ▓▒░
      </Title>
      <TextGroup style={{ marginBottom: 64, marginTop: 16 }}>
        <p>
          Before computers became more advanced, the text was monospaced.
          Each character, from a thin lowercase “i” to the wide uppercase “M” took up the same amount of screen space.
        </p>
        <p>
          For modern compatibility with Chinese, Korean, and Japanese characters — which are inherently monospaced — Unicode has
          designated ｆｕｌｌ　ｗｉｄｔｈ Roman alphanumeric characters.
        </p>
        <p>
          These characters allow you to evoke the old-school computer aesthetic of vaporwave in any text input online.
        </p>
      </TextGroup>
    </FontGenerator>
  )
}

export default Vaporwave
