import React, { useState } from 'react'

import { Dropdown } from '../../../components/Dropdown'
import { ProgressBar } from '../../../components/ProgressBar'
import { Search } from '../../../components/Search'

import { CardWithPreview } from './components/CardWithPreview'

import { previewText as backwardsPreviewText } from '../../FontGenerator/Backwards'
import { previewText as boldPreviewText } from '../../FontGenerator/Bold'
import { previewText as circledPreviewText } from '../../FontGenerator/Circled'
import { previewText as cursivePreviewText } from '../../FontGenerator/Cursive'
import { previewText as gothicPreivewText } from '../../FontGenerator/Gothic'
import { previewText as italicPreviewText } from '../../FontGenerator/Italic'
import { previewText as outlinePreviewText } from '../../FontGenerator/Outline'
import { previewText as sansSerifPreviewText } from '../../FontGenerator/SansSerif'
import { previewText as serifPreviewText } from '../../FontGenerator/Serif'
import { previewText as smallcapsPreviewText } from '../../FontGenerator/SmallCaps'
import { previewText as spongebobPreviewText } from '../../FontGenerator/Spongebob'
import { previewText as squarePreviewText } from '../../FontGenerator/Square'
import { previewText as strikethroughPreviewText } from '../../FontGenerator/Strikethrough'
import { previewText as superscriptPreviewText } from '../../FontGenerator/Superscript'
import { previewText as typewriterPreviewText } from '../../FontGenerator/Typewriter'
import { previewText as underlinePreviewText } from '../../FontGenerator/Underline'
import { previewText as upsidedownPreviewText } from '../../FontGenerator/Upsidedown'
import { previewText as vaporwavePreviewText } from '../../FontGenerator/Vaporwave'
import { previewText } from '../../FontGenerator/Zalgo'

import styles from './styles.module.scss'
import classNames from 'classnames'

export const fonts = [
  { name: 'Vaporwave', variantCount: 3, overrideDefaultText: 'VAPORWAVE', previewText: vaporwavePreviewText, link: '/vaporwave-text/', creative: true },
  { name: 'Backwards text', variantCount: 5, previewText: backwardsPreviewText, link: '/backwards-text/', creative: true },
  { name: 'Bold text', variantCount: 6, link: '/bold-text/', previewText: boldPreviewText, traditional: true },
  { name: 'Cursive text', variantCount: 4, previewText: cursivePreviewText, traditional: true, link: '/cursive/' },
  { name: 'Fraktur/Gothic/Blackletter', variantCount: 2, link: '/gothic/', previewText: gothicPreivewText, creative: true },
  { name: 'Circled', variantCount: 2, previewText: circledPreviewText, creative: true },
  { name: 'Squared', variantCount: 2, link: '/square/', previewText: squarePreviewText, creative: true },
  { name: 'Monospace/typewriter', variantCount: 1, previewText: typewriterPreviewText, link: '/typewriter/', creative: true },
  { name: 'Double struck/outlined', variantCount: 1, previewText: outlinePreviewText, link: '/outline/', creative: true },
  { name: 'Serif', variantCount: 2, previewText: serifPreviewText, traditional: true },
  { name: 'Sans serif', variantCount: 4, previewText: sansSerifPreviewText, traditional: true },
  { name: 'Italic text', variantCount: 5, previewText: italicPreviewText, link: '/italic/', traditional: true },
  { name: 'Small caps', variantCount: 1, previewText: smallcapsPreviewText, traditional: true },
  { name: 'Mocking Spongebob', variantCount: 6, previewText: spongebobPreviewText, link: '/spongebob/', creative: true },
  { name: 'Strikethrough', variantCount: 7, previewText: strikethroughPreviewText, traditional: true },
  { name: 'Superscript', variantCount: 2, previewText: superscriptPreviewText, traditional: true },
  { name: 'Underline', variantCount: 11, previewText: underlinePreviewText, link: '/underline-text/', traditional: true },
  { name: 'Upside down', variantCount: 3, previewText: upsidedownPreviewText, link: '/upside-down-text/', creative: true },
  {
    name: 'Zalgo text generator',
    variantCount: 'Multiple variations',
    link: '/zalgo-text/',
    previewText,
    clientOnlyPreview: true,
    creative: true,
    className: classNames(styles.zalgo, styles.mono)
  }
]

type FontOption = {
  label: string
  value: FontStyle
}

const fontStyleOptions: FontOption[] = [
  { label: 'All', value: 'all' },
  { label: 'Traditional font styles', value: 'traditional' },
  { label: 'Creative font styles', value: 'creative' }
]

type FontStyle = 'creative' | 'traditional' | 'all'

function subtitle (count: number | string): string {
  if (typeof count === 'number') {
    return `${count} ${count > 1 ? 'variations' : 'variation'}`
  }
  return count
}

export const FontGeneratorTab: React.FC = () => {
  const [text, setText] = useState<string | undefined>()
  const [fontStyle, setFontStyle] = useState<FontStyle>(fontStyleOptions[0].value)
  const [fontSize, setFontSize] = useState(16)

  return (
    <>
      <Search className={styles.search} value={text} onChange={setText} />
      <div className={styles.row}>
        <Dropdown nameClassName={styles['dropdown-name']} name="Show" options={fontStyleOptions} value={fontStyle} onChange={setFontStyle as any} />
        <ProgressBar nameClassName={styles['progress-slider-name']} min={12} max={72} value={fontSize} onChange={setFontSize} />
      </div>
      <div className={styles['fonts-container']}>
        {fonts
          .filter(font => fontStyle === 'all' ? true : font[fontStyle])
          .map(font => (
            <CardWithPreview
              key={font.name}
              title={font.name}
              subtitle={subtitle(font.variantCount)}
              text={text || (font.overrideDefaultText || font.name)}
              generatePreview={font.previewText}
              textFontSize={fontSize}
              link={font.link ?? `/${font.name.toLowerCase().replace(/\s/g, '-')}/`}
              textClassName={font.className}
            />
          ))}
      </div>
    </>
  )
}
