import React from 'react'

import { Table } from '../../../components/Table'
import { TextGroup } from '../../../components/TextGroup'

import { FontGenerator } from '../index'

import { upsidedown, upsidedownAndReverseSentence, upsidedownAndReversed } from './functions'

import { table, table2, tags } from './constants'

import styles from './styles.module.scss'

const upsidedownVariants = [
  { name: 'Upside down and reversed', fn: upsidedownAndReversed },
  { name: 'Upside down text', fn: upsidedown },
  { name: 'Upside down and reverse sentence', fn: upsidedownAndReverseSentence }
]

export const previewText = upsidedownVariants[0].fn

const Upsidedown: React.FC = () => {
  return (
    <FontGenerator
      variants={upsidedownVariants}
      subtitleClassName={styles.subtitle}
      tags={tags}
    >
      <TextGroup title='How does this tool work?' style={{ marginTop: 64 }}>
        <p>
          The upside down text generator works by swapping the basic letters of the Roman alphabet with similar characters from other languages and sets of symbols.
          This gives the impression of the letters being flipped 180 degrees.
        </p>
        <p>
          Some of the characters don’t require flipping, such as “O” and “X”.
          Some of them have an easy replacement in the Roman alphabet (e.g. “b” can be swapped with “q”).
        </p>
        <p>
          The upside down and reversed text does the same thing, but also reverses the order of the letters.
        </p>
      </TextGroup>
      <TextGroup title='Where do the flipped letters come from?' style={{ marginTop: 48 }}>
        <p>
          Unicode, which is basically an agreement on which letters computers can use, has over 100,000 possible characters.
          This includes the letters from thousands of languages, mathematical symbols, and emojis ☝️.
        </p>
        <p>
          Many of the symbols used in this tool come from the International Phonetic Alphabet (IPA).
          This alphabet is used to note all the possible sounds a human can create with their voice.
          Other upside down text symbols have more obscure origins, as seen in the table below.
        </p>
      </TextGroup>
      <Table
        className={styles.table}
        title='Upside down character table'
        columnsWidth='55px 55px 9fr'
        firstRowIsHeaders
        style={{ marginTop: 48 }}
      >
        {table}
      </Table>
      <Table
        className={styles.table}
        columnsWidth='55px 55px 9fr'
        style={{ marginTop: 48 }}
      >
        {table2}
      </Table>
    </FontGenerator>
  )
}

export default Upsidedown
