import React from 'react'

import { TextGroup } from '../../../components/TextGroup'

import { FontGenerator } from '../index'

import { monospace } from '../functions'

import type { Tool } from '../../../components/MoreTools'

import { Link } from 'react-router-dom'
import styles from './styles.module.scss'
import { strikethroughGeneratorTool, underlineTextTool } from '../../tools'

const typewriterVariants = [
  { name: 'Monospace / typewriter', fn: monospace }
]

export const previewText = typewriterVariants[0].fn

const tags = [
  { name: 'Unicode' },
  { name: 'Tools' },
  { name: 'Fonts' },
  { name: 'Hacks' },
  { name: 'Text' },
  { name: 'Meta' }
]

const Typewriter: React.FC = () => {
  return (
    <FontGenerator
      variants={typewriterVariants}
      subtitleClassName={styles.subtitle}
      tags={tags}
      moreTools={[strikethroughGeneratorTool, underlineTextTool]}
    >
      <TextGroup title='Monospace / typewriter' style={{ marginTop: 64 }}>
        <p>Monospaced fonts were created for typewriters. For practical purposes, every character needed to be the same width.</p>
        <p>They’re still used in computer terminals and code editors as they align each line of code.</p>
        <p>
          You can also use these characters to create tables and calendars, like in the&nbsp;
          <Link to='/calendar/'>Unicode calendar generator</Link>.
        </p>
      </TextGroup>
    </FontGenerator>
  )
}

export default Typewriter
