import React from 'react'
import cn from 'classnames'

import styles from './styles.module.scss'

type Props = {
  value?: string
  onChange: (value: string) => void
  className?: string
}

export const Search: React.FC<Props> = ({ className = '', onChange, value = '' }) => {
  return (
    <input className={cn(className, styles.input)} type='text' placeholder='Type something or paste some text' onChange={e => onChange(e.target.value)} value={value} />
  )
}
