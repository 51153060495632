import { monospaceDict, doublestruckDict } from '../constants'

export const spongecase = (text: string = 'mOcKiNg sPoNgEbOb'): string => {
  return text
    .split('')
    .map((e, i) => (i % 2 ? e.toUpperCase() : e.toLowerCase()))
    .join('')
}

export const spongecaseAlternate = (text: string = 'MoCkInG SpOnGeBoB'): string => {
  return text
    .split('')
    .map((e, i) => (i % 2 ? e.toLowerCase() : e.toUpperCase()))
    .join('')
}

export const doubleStruckSpongecase = (text: string = '𝕞𝕆𝕔𝕂𝕚ℕ𝕘 𝕤ℙ𝕠ℕ𝕘𝔼𝕓𝕆𝕓'): string => {
  const spongebobbed = text
    .split('')
    .map((e, i) => (i % 2 ? e.toUpperCase() : e.toLowerCase()))
    .join('')
  return spongebobbed
    .split('')
    .map(e => doublestruckDict[e] || e)
    .join('')
}

export const doubleStruckSpongecaseAlternate = (text: string = '𝕄𝕠ℂ𝕜𝕀𝕟𝔾 𝕊𝕡𝕆𝕟𝔾𝕖𝔹𝕠𝔹'): string => {
  const spongebobbed = text
    .split('')
    .map((e, i) => (i % 2 ? e.toLowerCase() : e.toUpperCase()))
    .join('')
  return spongebobbed
    .split('')
    .map(e => doublestruckDict[e] || e)
    .join('')
}

export const monospacedSpongecase = (text: string = '𝚖𝙾𝚌𝙺𝚒𝙽𝚐 𝚜𝙿𝚘𝙽𝚐𝙴𝚋𝙾𝚋'): string => {
  const spongebobbed = text
    .split('')
    .map((e, i) => (i % 2 ? e.toUpperCase() : e.toLowerCase()))
    .join('')
  return spongebobbed
    .split('')
    .map(e => monospaceDict[e] || e)
    .join('')
}

export const monospacedSpongecaseAlternate = (text: string = '𝙼𝚘𝙲𝚔𝙸𝚗𝙶 𝚂𝚙𝙾𝚗𝙶𝚎𝙱𝚘𝙱'): string => {
  const spongebobbed = text
    .split('')
    .map((e, i) => (i % 2 ? e.toLowerCase() : e.toUpperCase()))
    .join('')
  return spongebobbed
    .split('')
    .map(e => monospaceDict[e] || e)
    .join('')
}
