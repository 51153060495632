import React from 'react'

import { Title } from '../Title'

import styles from './styles.module.scss'

type TextWithIcon = {
  icon: React.ReactNode
  title: string
  text: string | React.ReactNode
}

type Props = {
  title: string
  subtitle?: string
  textWithIcon: TextWithIcon[]
  style?: React.CSSProperties
}

export const FocusSection: React.FC<Props> = ({ title, subtitle, textWithIcon, style }) => {
  return (
    <div className={styles.container} style={style}>
          <div className={styles['small-container']}>
            <Title size={subtitle ? 4 : 3}>{title}</Title>
            {subtitle ? <small className={styles.subtitle}>{subtitle}</small> : null}
            <div className={styles['icon-and-text-container']}>
              {textWithIcon.map(item => {
                return (
                  <div className={styles['icon-and-text']} key={item.title}>
                    {item.icon}
                    <div className={styles['icon-and-text-text']}>
                      <b className={styles['text-with-image-title']}>{item.title}</b>
                      <div className={styles['secondary-text']}>{item.text}</div>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
    </div>
  )
}
