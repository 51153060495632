import React from 'react'

import { TextGroup } from '../../../components/TextGroup'

import { FontGenerator } from '../index'

import { fraktur, frakturBold } from '../functions'

import styles from './styles.module.scss'
import { strikethroughGeneratorTool, underlineTextTool } from '../../tools'

const gothicVariants = [
  { name: 'Gothic / Blackletter', fn: fraktur },
  { name: 'Gothic / Blackletter bold', fn: frakturBold }
]

export const previewText = gothicVariants[0].fn

const tags = [
  { name: 'Unicode' },
  { name: 'Tools' },
  { name: 'Fonts' },
  { name: 'Hacks' },
  { name: 'Text' },
  { name: 'Meta' }
]

const Gothic: React.FC = () => {
  return (
    <FontGenerator
      variants={gothicVariants}
      subtitleClassName={styles.subtitle}
      tags={tags}
      moreTools={[strikethroughGeneratorTool, underlineTextTool]}
    >
      <TextGroup title='Fraktur / Blackletter / Gothic' style={{ marginTop: 64 }}>
        <p>
          Fraktur is a form of Blackletter or Gothic script. It was first used to decorate religious texts, including the earliest printed book: the Gutenberg Bible.
        </p>
        <p>It looks great as a heading, but it can be hard to read. Avoid using it in large blocks of text.</p>
      </TextGroup>
    </FontGenerator>
  )
}

export default Gothic
