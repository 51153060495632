import React from 'react'

import { TextGroup } from '../../../components/TextGroup'

import { FontGenerator } from '../index'

import { circled, circledBlack } from '../functions'

import styles from './styles.module.scss'
import { squaredFontTool, strikethroughGeneratorTool } from '../../tools'

const subtitle = 'Copy and paste 🅒🅘🅡🅒🅛🅔🅓 text you can use anywhere online, including instant messages, social media, emails, and more.'

const circleVariants = [
  { name: 'Circled / enclosed alphanumerics', fn: circled },
  { name: 'Negative circled / enclosed alphanumerics', fn: circledBlack }
]

export const previewText = circleVariants[0].fn

const tags = [
  { name: 'Unicode' },
  { name: 'Tools' },
  { name: 'Fonts' },
  { name: 'Hacks' },
  { name: 'Text' },
  { name: 'Meta' }
]

const Circle: React.FC = () => {
  return (
    <FontGenerator
      variants={circleVariants}
      subtitleClassName={styles.subtitle}
      tags={tags}
      moreTools={[strikethroughGeneratorTool, squaredFontTool]}
    >
      <TextGroup title='Circled / enclosed alphanumerics' style={{ marginTop: 64 }}>
        Circled or enclosed alphanumerics are useful for making your text stand out.
        The circled characters can be used individually as bullet points or in a string of letters and words.
      </TextGroup>
    </FontGenerator>
  )
}

export default Circle
