import React, { useEffect, useState } from 'react'

import { Card } from '../../../../../components/Card'

import styles from './styles.module.scss'

type Props = {
  title: string
  subtitle: string
  text?: string
  generatePreview: (text?: string) => string
  textFontSize: number
  link: string
  textClassName?: string
}

export const CardWithPreview: React.FC<Props> = ({ text, generatePreview, ...rest }) => {
  const [previewText, setPreviewText] = useState('')

  useEffect(() => {
    setPreviewText(generatePreview(text))
  }, [text])

  return (
    <Card
      className={styles.small}
      text={previewText}
      {...rest}
    />
  )
}
