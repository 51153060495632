import { original, reversedDict } from './constants'

export const backwards = (text: string = ''): string => {
  if (text === '') { return 'txeT sdrawkcaB' }
  return text.split('')
    .reverse()
    .join('')
}

export const mirrored = (text: string = ''): string => {
  if (text === '') { return 'miɿɿoɿɘb lɘɟɟɘɿs onlγ' }
  return text
    .split('')
    .map(e => reversedDict[original.indexOf(e)] || e)
    .join('')
}

export const backwardsWordsOnly = (text: string = ''): string => {
  if (text === '') { return 'sdrawkcaB sdroW ylnO' }
  return text.split('')
    .reverse()
    .join('')
    .split(' ').reverse().join(' ')
}

export const mirroredLettersOnly = (text: string = ''): string => {
  if (text === '') { return 'bɘɿoɿɿim' }
  return text
    .split('').reverse()
    .map(e => reversedDict[original.indexOf(e)] || e)
    .join('')
}

export const mirroredWordsAndLetters = (text: string = ''): string => {
  if (text === '') { return 'bɘɿoɿɿim sbɿow bnɒ sɘɔnɘɟnɘs' }
  return text
    .split('').reverse()
    .map(e => reversedDict[original.indexOf(e)] || e)
    .join('')
    .split(' ').reverse().join(' ')
}
