import { doublestruckDict, frakturBoldDict, monospaceDict } from '../constants'

export const allChars = [
  '\u030D',
  '\u030E',
  '\u0304',
  '\u0305',
  '\u033F',
  '\u0311',
  '\u0306',
  '\u0310',
  '\u0352',
  '\u0357',
  '\u0351',
  '\u0307',
  '\u0308',
  '\u030A',
  '\u0342',
  '\u0343',
  '\u0344',
  '\u034A',
  '\u034B',
  '\u034C',
  '\u0303',
  '\u0302',
  '\u030C',
  '\u0350',
  '\u0300',
  '\u0301',
  '\u030B',
  '\u030F',
  '\u0312',
  '\u0313',
  '\u0314',
  '\u033D',
  '\u0309',
  '\u0363',
  '\u0364',
  '\u0365',
  '\u0366',
  '\u0367',
  '\u0368',
  '\u0369',
  '\u036A',
  '\u036B',
  '\u036C',
  '\u036D',
  '\u036E',
  '\u036F',
  '\u033E',
  '\u035B',
  '\u0346',
  '\u031A',
  '\u0315',
  '\u031B',
  '\u0340',
  '\u0341',
  '\u0358',
  '\u0321',
  '\u0322',
  '\u0327',
  '\u0328',
  '\u0334',
  '\u0335',
  '\u0336',
  '\u035C',
  '\u035D',
  '\u035E',
  '\u035F',
  '\u0360',
  '\u0362',
  '\u0338',
  '\u0337',
  '\u0361',
  '\u0489',
  '\u0316',
  '\u0317',
  '\u0318',
  '\u0319',
  '\u031C',
  '\u031D',
  '\u031E',
  '\u031F',
  '\u0320',
  '\u0324',
  '\u0325',
  '\u0326',
  '\u0329',
  '\u032A',
  '\u032B',
  '\u032C',
  '\u032D',
  '\u032E',
  '\u032F',
  '\u0330',
  '\u0331',
  '\u0332',
  '\u0333',
  '\u0339',
  '\u033A',
  '\u033B',
  '\u033C',
  '\u0345',
  '\u0347',
  '\u0348',
  '\u0349',
  '\u034D',
  '\u034E',
  '\u0353',
  '\u0354',
  '\u0355',
  '\u0356',
  '\u0359',
  '\u035A',
  '\u0323'
]

export const upDownChars = [
  '\u030D',
  '\u030E',
  '\u0304',
  '\u0305',
  '\u033F',
  '\u0311',
  '\u0306',
  '\u0310',
  '\u0352',
  '\u0357',
  '\u0351',
  '\u0307',
  '\u0308',
  '\u030A',
  '\u0342',
  '\u0343',
  '\u0344',
  '\u034A',
  '\u034B',
  '\u034C',
  '\u0303',
  '\u0302',
  '\u030C',
  '\u0350',
  '\u0300',
  '\u0301',
  '\u030B',
  '\u030F',
  '\u0312',
  '\u0313',
  '\u0314',
  '\u033D',
  '\u0309',
  '\u0363',
  '\u0364',
  '\u0365',
  '\u0366',
  '\u0367',
  '\u0368',
  '\u0369',
  '\u036A',
  '\u036B',
  '\u036C',
  '\u036D',
  '\u036E',
  '\u036F',
  '\u033E',
  '\u035B',
  '\u0346',
  '\u031A',
  '\u0316',
  '\u0317',
  '\u0318',
  '\u0319',
  '\u031C',
  '\u031D',
  '\u031E',
  '\u031F',
  '\u0320',
  '\u0324',
  '\u0325',
  '\u0326',
  '\u0329',
  '\u032A',
  '\u032B',
  '\u032C',
  '\u032D',
  '\u032E',
  '\u032F',
  '\u0330',
  '\u0331',
  '\u0332',
  '\u0333',
  '\u0339',
  '\u033A',
  '\u033B',
  '\u033C',
  '\u0345',
  '\u0347',
  '\u0348',
  '\u0349',
  '\u034D',
  '\u034E',
  '\u0353',
  '\u0354',
  '\u0355',
  '\u0356',
  '\u0359',
  '\u035A',
  '\u0323'
]

export const upChars = [
  '\u030D',
  '\u030E',
  '\u0304',
  '\u0305',
  '\u033F',
  '\u0311',
  '\u0306',
  '\u0310',
  '\u0352',
  '\u0357',
  '\u0351',
  '\u0307',
  '\u0308',
  '\u030A',
  '\u0342',
  '\u0343',
  '\u0344',
  '\u034A',
  '\u034B',
  '\u034C',
  '\u0303',
  '\u0302',
  '\u030C',
  '\u0350',
  '\u0300',
  '\u0301',
  '\u030B',
  '\u030F',
  '\u0312',
  '\u0313',
  '\u0314',
  '\u033D',
  '\u0309',
  '\u0363',
  '\u0364',
  '\u0365',
  '\u0366',
  '\u0367',
  '\u0368',
  '\u0369',
  '\u036A',
  '\u036B',
  '\u036C',
  '\u036D',
  '\u036E',
  '\u036F',
  '\u033E',
  '\u035B',
  '\u0346',
  '\u031A'
]

export const midChars = [
  '\u0315',
  '\u031B',
  '\u0340',
  '\u0341',
  '\u0358',
  '\u0321',
  '\u0322',
  '\u0327',
  '\u0328',
  '\u0334',
  '\u0335',
  '\u0336',
  '\u035C',
  '\u035D',
  '\u035E',
  '\u035F',
  '\u0360',
  '\u0362',
  '\u0338',
  '\u0337',
  '\u0361',
  '\u0489'
]

export const downChar = [
  '\u0316',
  '\u0317',
  '\u0318',
  '\u0319',
  '\u031C',
  '\u031D',
  '\u031E',
  '\u031F',
  '\u0320',
  '\u0324',
  '\u0325',
  '\u0326',
  '\u0329',
  '\u032A',
  '\u032B',
  '\u032C',
  '\u032D',
  '\u032E',
  '\u032F',
  '\u0330',
  '\u0331',
  '\u0332',
  '\u0333',
  '\u0339',
  '\u033A',
  '\u033B',
  '\u033C',
  '\u0345',
  '\u0347',
  '\u0348',
  '\u0349',
  '\u034D',
  '\u034E',
  '\u0353',
  '\u0354',
  '\u0355',
  '\u0356',
  '\u0359',
  '\u035A',
  '\u0323'
]

export const letters = [
  '\u0363',
  '\u0364',
  '\u0365',
  '\u0366',
  '\u0367',
  '\u0368',
  '\u0369',
  '\u036A',
  '\u036B',
  '\u036C',
  '\u036D',
  '\u036E',
  '\u036F'
]

export const bars = [
  '\u035E',
  '\u035F',
  '\u033F',
  '\u0304',
  '\u0305',
  '\u0320',
  '\u0331',
  '\u0332',
  '\u0333'
]

export const charsetNameOptions = [
  { name: 'All', set: allChars },
  { name: 'Up and down', set: upDownChars },
  { name: 'Up', set: upChars },
  { name: 'Mid', set: midChars },
  { name: 'Down', set: downChar },
  { name: 'Letters', set: letters },
  { name: 'Bars', set: bars }
]

export const fontOptions = [
  { name: 'Default' },
  { name: '𝕱𝖗𝖆𝖐𝖙𝖚𝖗', value: frakturBoldDict },
  { name: '𝙼𝚘𝚗𝚘𝚜𝚙𝚊𝚌𝚎', value: monospaceDict },
  { name: '𝔻𝕠𝕦𝕓𝕝𝕖 𝕤𝕥𝕣𝕦𝕔𝕥', value: doublestruckDict }

]

export const tags = [
  { name: 'Unicode' },
  { name: 'Tools' },
  { name: 'Fonts' },
  { name: 'Hacks' },
  { name: 'Text' },
  { name: 'Meta' },
  { name: 'Glitch' }
]
