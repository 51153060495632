import React from 'react'

import { Title } from '../Title'

import Logo from './assets/Logo.svg'

import CardMakerLogo from './assets/card-maker-logo.svg?url'
import CardMakerLogoColorful from './assets/card-maker-logo-colorful.svg?url'

import LogoMakerLogo from './assets/logo-maker-logo.svg?url'
import LogoMakerLogoColorful from './assets/logo-maker-logo-colorful.svg?url'

import NameGeneratorLogo from './assets/name-generator-logo.svg?url'
import NameGeneratorLogoColorful from './assets/name-generator-logo-colorful.svg?url'

import SiteMakerLogo from './assets/site-maker-logo.svg?url'
import SiteMakerLogoColorful from './assets/site-maker-logo-colorful.svg?url'

import StencilLogo from './assets/stencil-logo.svg?url'
import StencilLogoColorful from './assets/stencil-logo-colorful.svg?url'

import styles from './styles.module.scss'

const firstParagraph = 'Font Maker is part of Visual — a suite of creative design tools exclusive to Namecheap.'
const secondParagraph = `
Visual is a simple and affordable collection of creative tools that helps small businesses, entrepreneurs, and freelancers bring their brands to life.
Visual makes it easy for anyone of any skill level to design all the essential elements of their brand.
From creating a logo for your business, to building a basic website, to designing business cards, Visual can help bring awareness to any brand.
Check out our other Visual tools:
`
const sites = [
  { name: 'Logo Maker', link: 'https://www.namecheap.com/logo-maker/' },
  { name: 'Card Maker', link: 'https://www.namecheap.com/visual/card-maker/' },
  { name: 'Site Maker', link: 'https://www.namecheap.com/visual/site-maker/' },
  { name: 'Stencil', link: 'https://www.namecheap.com/visual/stencil/' },
  { name: 'Business Name Generator', link: 'https://www.namecheap.com/visual/business-name-generator/' }
]

const logos = [
  { monochrome: SiteMakerLogo, colorful: SiteMakerLogoColorful, name: 'SiteMakerLogo' },
  { monochrome: CardMakerLogo, colorful: CardMakerLogoColorful, name: 'CardMakerLogo' },
  { monochrome: StencilLogo, colorful: StencilLogoColorful, name: 'StencilLogo' },
  { monochrome: NameGeneratorLogo, colorful: NameGeneratorLogoColorful, name: 'NameGeneratorLogo' },
  { monochrome: LogoMakerLogo, colorful: LogoMakerLogoColorful, name: 'LogoMakerLogo' }
]

export const Discover: React.FC = () => {
  return (
    <div className={styles.container}>
      <section className={styles.section}>
        <Logo />
        <Title white center>Discover Visual</Title>
        <div className={styles['text-container']}>
          <p className={styles.text}>
            {firstParagraph}
          </p>
          <p className={styles.text}>
            {secondParagraph}
            {sites.map((site, index) => {
              const last = sites.length - 1 === index
              const first = index === 0
              return (
                <span key={site.name + index}>
                  {`${last ? ', and ' : (first ? '' : ', ')}`}
                  <a className={styles.link} href={site.link}>{site.name}</a>
                  {last ? '.' : ''}
                </span>
              )
            })}
          </p>
        </div>
      </section>
      <div className={styles.logos}>
        {logos.map(({ monochrome, colorful, name }) =>
          <div
            className={styles['logo-container']}
            key={name}
          >
            <img src={colorful.toString()} />
            <img src={monochrome.toString()} />
          </div>
        )}
      </div>
    </div>
  )
}
