import React, { useState } from 'react'
import RCTooltip from 'rc-tooltip'

type Props = {
  text: string | React.ReactNode
  showOnClick?: boolean
  children: React.ReactElement
  timeout?: boolean
}

export const Tooltip: React.FC<Props> = ({ text, children, showOnClick = false, timeout = true }) => {
  const [visible, setVisible] = useState(false)
  return (
    <RCTooltip
      visible={visible}
      placement="top"
      trigger={showOnClick ? 'click' : 'hover'}
      overlay={<span>{text}</span>}
      align={{ offset: [0, -21] }}
      onVisibleChange={visible => {
        setVisible(visible)
      }}
      afterVisibleChange={newVisibility => {
        if (newVisibility && showOnClick && timeout) {
          setTimeout(() => setVisible(false), 2000)
        }
      }}
      getTooltipContainer={() => document.querySelector('.gb-font-maker-scope')!}
    >
      {children}
    </RCTooltip>
  )
}
