import React from 'react'
import cn from 'classnames'

import { Title } from '../Title'

import styles from './styles.module.scss'

type Props = {
  title?: string
  subtitle?: string
  children: string | React.ReactNode
  style?: React.CSSProperties
  biggerText?: boolean
  className?: string
}

export const TextGroup: React.FC<Props> = ({ title, subtitle, children: text, biggerText = false, style, className }) => {
  return (
    <div className={cn(styles.container, className, { [styles['bigger-text']]: biggerText })} style={style}>
      <div className={styles['small-container']}>
        {title ? <Title className={styles.title} size={3}>{title}</Title> : null}
        {subtitle ? <Title className={styles.subtitle} size={4} h={3}>{subtitle}</Title> : null}
        <div className={styles.text}>{text}</div>
      </div>
    </div>
  )
}
