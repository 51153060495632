import React from 'react'
import type { Option } from 'react-dropdown'
import ReactDropdown from 'react-dropdown'
import cn from 'classnames'
import CreatableSelect from 'react-select/creatable'

import styles from './styles.module.scss'

type Props = {
  name: string
  options: string[] | Option[]
  value: string | Option
  onChange: (option: any) => void
  nameClassName?: string
  small?: boolean
  input?: boolean
}

export const Dropdown: React.FC<Props> = ({ nameClassName = '', name, value, options, onChange, small = false, input }) => {
  return (
    <div className={cn(styles.container, { [styles.small]: small })}>
      <span className={cn(nameClassName, styles.name)}>{name}</span>
      {
        input
          ? (
              <CreatableSelect<Option>
                value={value as unknown as Option}
                className={styles['react-select']}
                isSearchable
                name={name}
                options={options as Option[]}
                onChange={option => onChange(option?.value)}
                formatCreateLabel={value => <span>{value}</span>}
                styles={{
                  control: base => ({
                    ...base,
                    minHeight: 32,
                    height: 32,
                    flexWrap: 'nowrap',
                    minWidth: 150,
                    borderColor: '#ACADAF!important',
                    position: 'relative',
                    cursor: 'pointer',
                    boxShadow: 'none'
                  }),
                  valueContainer: base => ({
                    ...base,
                    color: '#4A4A4A',
                    fontSize: '16px',
                    fontWeight: 600,
                    fontFamily: 'Museo Sans'
                  }),
                  singleValue: base => ({
                    ...base,
                    color: '#4A4A4A'
                  }),
                  indicatorSeparator: base => ({
                    ...base,
                    display: 'none'
                  }),
                  indicatorsContainer: base => ({
                    ...base,
                    borderColor: '#999 transparent transparent',
                    borderStyle: 'solid',
                    borderWidth: '5px 5px 0',
                    content: '" "',
                    display: 'block',
                    height: 0,
                    marginTop: '-ceil(2.5)',
                    position: 'absolute',
                    right: '10px',
                    top: '14px',
                    width: 0
                  }),
                  menu: base => ({
                    ...base,
                    marginTop: 0,
                    borderRadius: 0
                  }),
                  option: base => ({
                    ...base,
                    color: 'rgba(51, 51, 51, 0.8)',
                    backgroundColor: 'transparent',
                    '&:hover': {
                      ...base,
                      color: 'rgba(51, 51, 51, 0.8)',
                      backgroundColor: '#f2f9fc'
                    }
                  })
                }}
              />
            )
          : (
              <ReactDropdown
                controlClassName={styles.dropdown}
                options={options}
                onChange={({ value }) => onChange(value)}
                value={value}
                placeholder="Select an option"
              />
            )
      }
    </div>
  )
}
