import { aestheticDict, aesthetic2Dict, aesthetic3Dict } from './constants'

export const aesthetic = (text: string = 'VAPORWAVE'): string => {
  return text
    .split('')
    .map(e => aestheticDict[e] || e)
    .join('')
}

export const aesthetic2 = (text: string = 'Ｖ\u200A\u200AΛ\u200A\u200AＰＯＲＷ\u200A\u200AΛ\u200A\u200AＶＥ\u3000Λ\u200A\u200AＥＳＴＨＥＴＩＣ'): string => {
  return text
    .split('')
    .map(e => aesthetic2Dict[e] || e)
    .join('')
}

export const aesthetic3 = (text: string = 'Ｖ\u200A\u200AΛ\u200A\u200AＰＯＲＷ\u200A\u200AΛ\u200A\u200AＶ\u200A\u200AΣ\u200A\u200A\u3000Λ\u200A\u200A\u200A\u200AΣ\u200A\u200AＳＴＨ\u200A\u200AΣ\u200A\u200AＴＩＣ'): string => {
  return text
    .split('')
    .map(e => aesthetic3Dict[e] || e)
    .join('')
}
