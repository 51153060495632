import React from 'react'
import cn from 'classnames'

import styles from './styles.module.scss'

type Props = {
  children: React.ReactNode
  className?: string
  h?: 1 | 2 | 3 | 4 | 5 | 6 | 7
  size?: 1 | 2 | 3 | 4 | 5
  center?: boolean
  style?: React.CSSProperties
  white?: boolean
}

export const Title: React.FC<Props> = ({ children, h = 2, size = 2, className, center = false, style, white = false }) => {
  const tagMapping = {
    1: 'h1',
    2: 'h2',
    3: 'h3',
    4: 'h4',
    5: 'h5',
    6: 'h6',
    7: 'b'
  }

  const H = tagMapping[h] as React.ElementType
  return (
    <H
      className={cn(className, styles.title, styles[`size-${size}`])}
      style={{
        ...style,
        textAlign: center ? 'center' : 'left',
        color: white ? 'white' : undefined
      }}>
        {children}
    </H>
  )
}
