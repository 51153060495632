import React from 'react'

import { TextGroup } from '../../../components/TextGroup'

import { FontGenerator } from '../index'

import { frakturBold, sansBold, sansBoldItalic, scriptBold, serifBold, serifBoldItalic } from '../functions'

import styles from './styles.module.scss'
import { italicTextTool, strikethroughGeneratorTool } from '../../tools'

const boldVariants = [
  { name: 'Sans serif bold', fn: sansBold },
  { name: 'Sans serif bold italic', fn: sansBoldItalic },
  { name: 'Serif bold', fn: serifBold },
  { name: 'Serif bold italic', fn: serifBoldItalic },
  { name: 'Fraktur bold', fn: frakturBold },
  { name: 'Script bold', fn: scriptBold }
]

export const previewText = boldVariants[0].fn

const tags = [
  { name: 'Unicode' },
  { name: 'Tools' },
  { name: 'Fonts' },
  { name: 'Hacks' },
  { name: 'Text' },
  { name: 'Meta' },
  { name: 'Italics' }
]

const Bold: React.FC = () => {
  return (
    <FontGenerator
      variants={boldVariants}
      subtitleClassName={styles.subtitle}
      showUnderline
      tags={tags}
      moreTools={[strikethroughGeneratorTool, italicTextTool]}
    >
      <TextGroup title='How does this tool work?' style={{ marginTop: 64 }}>
        The bold text is pulled from Unicode, the standardized system for computer characters.
        Unicode features over 134,000 characters, including almost every letter and glyph in every written language as well as shapes, numbers, emojis, and more.
      </TextGroup>
      <TextGroup title='Where can I use this text?' style={{ marginTop: 64 }}>
        <p>
          By using Unicode characters, you can add bold text in places where it’s normally not an option.
          For example, bold text is useful for distinguishing your Wi-Fi network, browser tabs, and bookmark folders.
        </p>
        <p>
          The bold characters featured here can be used pretty much everywhere online.
          This text works for posts and bios on social media platforms like Facebook, Instagram, X, and YouTube. Bold text can also be copied and pasted into instant messaging apps like iMessage and WhatsApp.
        </p>
      </TextGroup>
    </FontGenerator>
  )
}

export default Bold
