import React from 'react'
import cn from 'classnames'

import { Card } from '../Card'
import { Title } from '../Title'

import styles from './styles.module.scss'

export type Tool = {
  name: string
  variantCount: number | 'Multiple'
  text: string
  link: string
  className?: string
  additionalText?: string
}

type Props = {
  tools: Tool[]
  className?: string
}

function subtitle (count: number | string): string {
  if (typeof count === 'number') {
    return `${count} ${count > 1 ? 'variations' : 'variation'}`
  }
  return count
}

export const MoreTools: React.FC<Props> = ({ className, tools }) => {
  return (
    <div className={cn(styles.container, className)}>
      <div className={styles['small-container']}>
        <Title className={styles.title} size={3} h={2}>More tools</Title>
        {tools.map(tool => {
          return (
            <Card
              className={styles.card}
              textWrap
              key={tool.name}
              title={tool.name}
              subtitle={subtitle(tool.variantCount)}
              text={tool.text}
              textClassName={cn(tool.className, styles.text)}
              link={tool.link}
              hideIcon
              additionalText={tool.additionalText}
            />
          )
        })}
      </div>
    </div>
  )
}
