import {
  scriptBoldDict, serifBoldDict, serifBoldItalicDict, sansBoldDict, sansBoldItalicDict,
  accents, frakturBoldDict, scriptDict, frakturDict, sansItalicDict, squaredDict, squaredBlackDict, doublestruckDict, monospaceDict,
  circledDict, cicledBlackDict, sansDict
} from './constants'

export const parseMessage = (text: string): string => {
  return text
    .split('')
    .map(e => accents[e] || e)
    .join('')
}

export const serifBold = (text: string = 'Serif Bold', underline: string = ''): string => {
  return (
    underline +
    parseMessage(text)
      .split('')
      .map(e => serifBoldDict[e] || e)
      .join(underline)
  )
}

export const serifBoldItalic = (text: string = 'Serif Bold Italic', underline: string = ''): string => {
  return (
    underline +
    text
      .split('')
      .map(e => serifBoldItalicDict[e] || e)
      .join(underline)
  )
}

export const script = (text: string = 'Script', underline: string = ''): string => {
  return parseMessage(text)
    .split('')
    .map(e => scriptDict[e] || e)
    .join(underline)
}

export const scriptBold = (text: string = 'Script Bold', underline: string = ''): string => {
  return (
    underline +
    parseMessage(text)
      .split('')
      .map(e => scriptBoldDict[e] || e)
      .join(underline)
  )
}

export const fraktur = (text: string = '𝔉𝔯𝔞𝔨𝔱𝔲𝔯'): string => {
  return parseMessage(text)
    .split('')
    .map(e => frakturDict[e] || e)
    .join('')
}

export const frakturBold = (text: string = 'Fraktur bold', underline: string = ''): string => {
  return parseMessage(text)
    .split('')
    .map(e => frakturBoldDict[e] || e)
    .join(underline)
}

export const sansBold = (text: string = 'Sans-serif Bold', underline: string = ''): string => {
  return (
    underline +
    text
      .split('')
      .map(e => sansBoldDict[e] || e)
      .join(underline)
  )
}

export const sansItalic = (text: string = 'Sans-serif Italic', underline: string = ''): string => {
  return underline +
    text
      .split('')
      .map(e => sansItalicDict[e] || e)
      .join(underline)
}

export const sansBoldItalic = (text: string = 'Sans-serif Bold Italic', underline: string = ''): string => {
  return (
    underline +
    text
      .split('')
      .map(e => sansBoldItalicDict[e] || e)
      .join(underline)
  )
}

export const squared = (text: string = '🅂🅀🅄🄰🅁🄴🄳'): string => {
  return parseMessage(text)
    .split('')
    .map(e => squaredDict[e] || e)
    .join('')
}

export const squaredBlack = (text: string = '🅽🅴🅶🅰🆃🅸🆅🅴 🆂🆀🆄🅰🆁🅴🅳'): string => {
  return parseMessage(text)
    .split('')
    .map(e => squaredBlackDict[e] || e)
    .join('')
}

export const doublestruck = (text: string = '𝔻𝕠𝕦𝕓𝕝𝕖 𝕊𝕥𝕣𝕦𝕔𝕜'): string => {
  return parseMessage(text)
    .split('')
    .map(e => doublestruckDict[e] || e)
    .join('')
}

export const monospace = (text: string = '𝙼𝚘𝚗𝚘𝚜𝚙𝚊𝚌𝚎'): string => {
  return parseMessage(text)
    .split('')
    .map(e => monospaceDict[e] || e)
    .join('')
}

export const circled = (text: string = 'Ⓒⓘⓡⓒⓛⓔⓓ'): string => {
  return parseMessage(text)
    .split('')
    .map(e => circledDict[e] || e)
    .join('')
}

export const circledBlack = (text: string = '🅝🅔🅖🅐🅣🅘🅥🅔 🅒🅘🅡🅒🅛🅔🅓'): string => {
  return parseMessage(text)
    .split('')
    .map(e => cicledBlackDict[e] || e)
    .join('')
}

export const sans = (text: string = '𝖲𝖺𝗇𝗌-𝖲𝖾𝗋𝗂𝖿'): string => {
  return parseMessage(text)
    .split('')
    .map(e => sansDict[e] || e)
    .join('')
}
