import React from 'react'
import cn from 'classnames'

import styles from './styles.module.scss'

import LogoIcon from './assets/logo.svg'
import Beta from './assets/beta.svg'

type Props = {
  className?: string
}

export const Logo: React.FC<Props> = ({ className }) => {
  return (
    <div className={cn(styles.container, className)}>
      <LogoIcon className={styles.logo} />
      <Beta className={styles.beta} />
    </div>
  )
}
