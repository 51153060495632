import React from 'react'

import { TextGroup } from '../../../components/TextGroup'

import { FontGenerator } from '../index'

import { sansBoldItalic, sansItalic, script, scriptBold, serifBoldItalic } from '../functions'

import styles from './styles.module.scss'
import { boldTextTool, calendarTool } from '../../tools'

const italicVariants = [
  { name: 'Sans serif italic', fn: sansItalic },
  { name: 'Sans serif bold italic', fn: sansBoldItalic },
  { name: 'Serif bold italic', fn: serifBoldItalic },
  { name: 'Script', fn: script },
  { name: 'Script bold', fn: scriptBold }
]

export const previewText = italicVariants[0].fn

const tags = [
  { name: 'Unicode' },
  { name: 'Tools' },
  { name: 'Fonts' },
  { name: 'Hacks' },
  { name: 'Text' },
  { name: 'Meta' },
  { name: 'Italics' }
]

const Italic: React.FC = () => {
  return (
    <FontGenerator
      variants={italicVariants}
      subtitleClassName={styles.subtitle}
      showUnderline
      tags={tags}
      moreTools={[calendarTool, boldTextTool]}
    >
      <TextGroup title='How does this tool work?' style={{ marginTop: 64 }}>
        <p>This tool creates italicized text with Unicode, the standardized system of computer characters. </p>
        <p>
          More specifically, it takes advantage of the Mathematical Alphanumeric Symbols Unicode block.
          This is a special set of characters that can be used to create a consistent style in math equations.
        </p>
      </TextGroup>
      <TextGroup title='When should I use this tool?' style={{ marginTop: 32 }}>
        <p>You can use this tool to apply italics anywhere you want online, even in places where you typically can’t. </p>
        <p>For instance, you might want to emphasize a word or correctly format a citation on social media:</p>
        <p>According to the original 1891 patent, a toilet roll should hang <i>over</i>, not <i>under</i>.</p>
        <p><i>Wrapping or Toilet Paper Roll</i> — Patent No 459,516 | S. Wheeler</p>
      </TextGroup>
    </FontGenerator>
  )
}

export default Italic
