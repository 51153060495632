import React from 'react'

import { Title } from '../Title'

import styles from './styles.module.scss'

type Props = {
  title?: string
  subtitle?: string | React.ReactNode
  children?: string | React.ReactNode
  image?: React.ReactNode
  leftImage?: React.ReactNode
  style?: React.CSSProperties
}

export const TextWithImage: React.FC<Props> = ({ title, subtitle, children: text, image, leftImage, style }) => {
  return (
    <div className={styles.container} style={style}>
      <div className={styles['small-container']}>
        {leftImage}
        <div className={styles['text-container']}>
          {title ? <Title className={styles.title} size={3}>{title}</Title> : null}
          {subtitle ? <b className={styles.subtitle} style={{ marginTop: text ? 8 : 16 }}>{subtitle}</b> : null}
          {
            text
              ? (typeof text === 'string' ? <p className={styles.text}>{text}</p> : <div className={styles.text}>{text}</div>)
              : null
          }
        </div>
        {image}
      </div>
    </div>
  )
}
