import { monospaceDict, sansDict } from '../constants'

export const mono = (text: string = 'monospaced and struckthrough'): string => {
  return (
    text
      .split('')
      .map(e => monospaceDict[e] || e)
      .join('\u0336') + '\u0336'
  )
}

export const monoSlash = (text: string = 'Monospaced and slashed'): string => {
  return (
    text
      .split('')
      .map(e => monospaceDict[e] || e)
      .join('\u0337') + '\u0337'
  )
}

export const redacted = (text: string = 'REDACTED'): string => {
  return text.split(' ').map((string) => {
    return string
      .split('')
      .map(() => '█')
      .join('')
  }).join(' ')
}

export const unstyled = (text: string = 'struckthrough'): string => {
  return '\u0336' + text.split('').join('\u0336') + '\u0336'
}

export const unstyledSlash = (text: string = 'struckthrough'): string => {
  return text.split('').join('\u0337') + '\u0337'
}

export const sansSerif = (text: string = 'Struckthrough'): string => {
  return (
    text
      .split('')
      .map(e => sansDict[e] || e)
      .join('\u0336') + '\u0336'
  )
}

export const sansSerifSlash = (text: string = 'Struckthrough'): string => {
  return (
    text
      .split('')
      .map(e => sansDict[e] || e)
      .join('\u0337') + '\u0337'
  )
}
