export const original = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z', 'A',
  'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z']
export const reversedDict = ['ɒ', 'd', 'ɔ', 'b', 'ɘ', 'ʇ', 'ϱ', '⑁', 'i', 'ᒑ', 'ʞ', 'l', 'm', 'n', 'o', 'q', 'p', 'ɿ', 'ƨ', 'ɟ', 'u', 'v', 'w', 'x', 'γ',
  'z', 'A', 'ᗺ', 'Ɔ', 'ᗡ', 'Ǝ', 'ꟻ', 'ວ', 'H', 'I', 'ᒐ', 'ꓘ', '⅃', 'M', 'И', 'O', 'ᑫ', 'Ϙ', 'Я', 'Ƨ', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z']

export const tags = [
  { name: 'Unicode' },
  { name: 'Tools' },
  { name: 'Fonts' },
  { name: 'Hacks' },
  { name: 'Text' },
  { name: 'Meta' }
]

export const table = [
  ['a', 'ɒ', 'Latin Small Letter Turned Alpha — part of the International Phonetic Alphabet (IPA)'],
  ['b', 'd', 'Just the letter “d”'],
  ['c', 'ɔ', 'Latin Small Letter Open O — part of the IPA'],
  ['d', 'b', 'Just the letter “b”'],
  ['e', 'ɘ', 'Latin Small Letter Reversed E — part of the IPA'],
  ['f', 'ʇ', 'Latin Small Letter Turned T — part of the IPA'],
  ['g', 'ϱ', 'Greek Rho symbol'],
  ['h', '⑁', 'OCR Chair — part of a set of characters used for electronic scanning'],
  ['i', 'i', 'Stays the same'],
  ['j', 'ᒑ', 'Canadian Syllabics Caa — part of a writing system for indigenous Canadian languages'],
  ['k', 'ʞ', 'Latin Small Letter Turned K – part of the IPA'],
  ['l', 'l', 'Stays the same'],
  ['m', 'm', 'Stays the same'],
  ['n', 'n', 'Stays the same'],
  ['o', 'o', 'Stays the same'],
  ['p', 'q', 'Just the letter “q”'],
  ['q', 'p', 'Just the letter “p”'],
  ['r', 'ɿ', 'Latin Small Letter Reversed R with Fishhook – part of the IPA'],
  ['s', 'ƨ', 'Latin Small Letter Tone Two – used in various obscure phonetic alphabets'],
  ['t', 'ɟ', 'Latin Small Letter Dotless J with Stroke – part of the IPA'],
  ['u', 'u', 'Stays the same'],
  ['v', 'v', 'Stays the same'],
  ['w', 'w', 'Stays the same'],
  ['x', 'x', 'Stays the same'],
  ['y', 'γ', 'Greek small letter Gamma'],
  ['z', 'z', 'Stays the same – no reasonable backwards “z” could be found']
]

export const table2 = [
  ['A', 'A', 'Stays the same'],
  ['B', 'ᗺ', 'Canadian syllabics carrier Kha'],
  ['C', 'Ɔ', 'Latin Capital Letter Open O'],
  ['D', 'ᗡ', 'Canadian Syllabics Carrier Tha'],
  ['E', 'Ǝ', 'Latin Capital Letter Reversed E'],
  ['F', 'ꟻ', 'Latin Epigraphic Letter Reversed F'],
  ['G', 'ວ', 'Lao letter Wo'],
  ['H', 'H', 'Stays the same'],
  ['I', 'I', 'Stays the same'],
  ['J', 'ᒐ', 'Canadian syllabics Ca'],
  ['K', 'ꓘ', 'Lisu letter Kha'],
  ['L', '⅃', 'Reversed Sans-Serif Capital L'],
  ['M', 'M', 'Stays the same'],
  ['N', 'И', 'Cyrillic capital letter I'],
  ['O', 'O', 'Stays the same'],
  ['P', 'ᑫ', 'Canadian syllabics Ke'],
  ['Q', 'Ϙ', 'Greek letter archaic Koppa'],
  ['R', 'Я', 'Cyrillic capital letter Ya'],
  ['S', 'Ƨ', 'Latin Capital Letter Tone Two'],
  ['T', 'T', 'Stays the same'],
  ['U', 'U', 'Stays the same'],
  ['V', 'V', 'Stays the same'],
  ['W', 'W', 'Stays the same'],
  ['X', 'X', 'Stays the same'],
  ['Y', 'Y', 'Stays the same'],
  ['Z', 'Z', 'Stays the same – no reasonable backwards “Z” could be found']
]
