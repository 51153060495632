import React from 'react'
import cn from 'classnames'
import sum from 'lodash.sum'

import styles from './styles.module.scss'

type Props = {
  title?: string
  children: string[][]
  columnsWidth?: number[] | string
  style?: React.CSSProperties
  firstRowIsHeaders?: boolean
  className?: string
}

export const Table: React.FC<Props> = ({ className, title: header, children: rows, style, firstRowIsHeaders = false, columnsWidth }) => {
  return (
    <div className={styles.container} style={style}>
      <div className={styles['small-container']}>
        {
          header
            ? <div className={styles.header} style={{ width: (columnsWidth && typeof columnsWidth !== 'string') ? sum(columnsWidth) : 'auto' }}>{header}</div>
            : null
        }
        <div
          className={cn(styles.table, className)}
          style={{
            gridTemplateColumns: columnsWidth
              ? (typeof columnsWidth === 'string' ? columnsWidth : columnsWidth.join('px ') + 'px')
              : Array(rows[0].length).fill('1fr').join(' ')
          }}
        >
          {rows.flatMap((row, index) => {
            return row.map((item, innerIndex) => {
              return (
                <span
                  key={item + innerIndex.toString() + index.toString()}
                  className={cn(styles.cell, { [styles['header-cell']]: index === 0 && firstRowIsHeaders })}
                >
                  {item}
                </span>
              )
            })
          })}
        </div>
      </div>
    </div>
  )
}
