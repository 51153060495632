import React from 'react'
import cn from 'classnames'
import { Link } from 'react-router-dom'

import { Tooltip } from '../Tooltip'

import CopyIcon from '../../assets/images/copy-icon.svg'

import styles from './styles.module.scss'

type Props = {
  title?: string
  subtitle?: string
  text: string
  textToShow?: React.ReactNode
  textFontSize?: number
  textAlignCenter?: boolean
  small?: boolean
  link?: string
  className?: string
  textClassName?: string
  hideIcon?: boolean
  style?: React.CSSProperties
  textWrap?: boolean
  additionalText?: string
}

export const Card: React.FC<Props> = ({
  title = '', subtitle = '', text,
  textToShow,
  textFontSize = 16, textAlignCenter = false, textClassName,
  small = false, link,
  className,
  hideIcon = false,
  style,
  textWrap = false,
  additionalText
}) => {
  const copyToClipboard = (e: React.MouseEvent): void => {
    e.preventDefault()
    navigator.clipboard.writeText(text) as any
  }

  const innerElements = (
    <>
      <div className={styles.row}>
        <div className={styles['inner-row']}>
          <b>{title}</b>
          {subtitle ? <small className={styles.subtitle}>{subtitle}</small> : null}
        </div>
        {
          hideIcon
            ? null
            : (
                <Tooltip text='Copied to clipboard!' showOnClick>
                  <div>
                    <Tooltip text='Copy to clipboard'>
                      <CopyIcon className={styles['copy-icon']} onClick={copyToClipboard} />
                    </Tooltip>
                  </div>
                </Tooltip>
              )
        }
      </div>
      {textToShow
        ? null
        : (
            <pre
              className={cn(textClassName, styles.text)}
              style={{ fontSize: textFontSize, textAlign: textAlignCenter ? 'center' : 'start', textWrap: textWrap ? 'wrap' : 'nowrap' } as any}
            >
              {text}
            </pre>
          )
      }
      {textToShow ? <p className={styles['text-to-show']}>{textToShow}</p> : null}
      {additionalText ? <p className={styles['additional-text']}>{additionalText}</p> : null}
    </>
  )

  if (link) {
    return (
      <Link className={cn(className, styles.container, { [styles.small]: small })} to={link} style={style}>
        {innerElements}
      </Link>
    )
  }

  return (
    <div className={cn(className, styles.container, { [styles.small]: small })} style={style}>
      {innerElements}
    </div>
  )
}
