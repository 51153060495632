/* eslint-disable no-irregular-whitespace */
import React from 'react'

import { TextGroup } from '../../../components/TextGroup'
import { TextTile } from '../../../components/TextTile'

import { FontGenerator } from '../index'

import { superscript, superscript2 } from './functions'

import { tags } from './constants'

import type { Tool } from '../../../components/MoreTools'

import styles from './styles.module.scss'
import { smallCapsTool, strikethroughGeneratorTool, underlineTextTool } from '../../tools'

const superscriptVariants = [
  { name: 'Style 1 superscript', fn: superscript },
  { name: 'Style 2 superscript', fn: superscript2 }
]

export const previewText = superscriptVariants[0].fn

const Superscript: React.FC = () => {
  return (
    <FontGenerator
      variants={superscriptVariants}
      subtitleClassName={styles.subtitle}
      showUnderline
      tags={tags}
      moreTools={[strikethroughGeneratorTool, underlineTextTool, smallCapsTool]}
    >
      <TextGroup title='How does this work?' style={{ marginTop: 64 }}>
        Unicode — the standard set of characters used by all computers — doesn't explicitly define a superscript alphabet.
        So, this tool works by combining characters from different sets to create one.
      </TextGroup>
      <TextTile style={{ marginTop: 64 }}>
        <b>International Phonetic Alphabet</b>
        <p>ᵃ ᵇ ᶜ ᵈ ᵉ ᶠ ᵍ ʰ ᶦ ʲ ᵏ ˡ ᵐ ᵒ ᵖ ᵠ ʳ ˢ ᵗ ᵘ ᵛ ʷ ˣ ʸ ᶻ</p>
        <b>Combining diacritical marks</b>
        <p> ͥ</p>
        <b>Superscripts and subscripts</b>
        <p>ⁿ ⁰ ⁴ ⁵ ⁶ ⁷ ⁸ ⁹ ⁺ ⁻ ⁼ ⁽ ⁾</p>
        <b>Latin Supplement</b>
        <p>¹ ² ³</p>
        <b>Modifier Tone Letters</b>
        <p>ꜝ</p>
      </TextTile>
      <TextGroup title='What’s the difference between the two styles?' style={{ marginTop: 64 }}>
        <p>
          Almost every letter of the alphabet has a good superscript alternative. It’s just “i” and “q” that are missing.
          The two styles deal with this issue in different ways.
        </p>
        <p>
          <b>Style 1</b> uses ᶦ and ᵠ. This has the benefit of working well in most places online, but there’s no dot on the “i” and that is clearly not a “q”.
        </p>
        <p>
          <b>Style 2</b> uses three characters for the “i”: a combining diacritical mark (i) — a glyph that combines with the previous letter, a zero-width space to stop the “i” from combining, and a “hair space” to add a gap between the “i” and the next letter.
        </p>
        <p>
          The “q” is created using the same character as the superscript “o” and a combining tilde overlay (~).
          This isn’t a perfect solution by any means, and these two alternative letters will sometimes fail to render correctly.
          For this reason, you may want to avoid the use of the letter “q” in superscript text.
        </p>
      </TextGroup>
      <TextGroup title='When should I use superscript?' style={{ marginTop: 64 }}>
        You can use superscript whenever and wherever you feel like it. That being said, there are a few specific instances where you may want to apply this text style.
      </TextGroup>
      <TextGroup subtitle='CITATIONS' style={{ marginTop: 16 }}>
        <p>If you need to use Chicago style¹ citations but don’t have access to a regular superscript, this tool can fill the gap.</p>
        <p className={styles.citations}>1. <a href='https://www.chicagomanualofstyle.org/tools_citationguide.html'>https://www.chicagomanualofstyle.org/tools_citationguide.html</a></p>
      </TextGroup>
      <TextGroup subtitle='ORDINAL INDICATORS' style={{ marginTop: 32 }}>
        Although 1st, 2nd, and 3rd are perfectly acceptable, you may want to create a cleaner look with 1ˢᵗ, 2ⁿᵈ, and 3ʳᵈ.
      </TextGroup>
      <TextGroup subtitle='MATH' style={{ marginTop: 32 }}>
        Writing powers with superscript — 𝓍⁹ — looks much better than using a circumflex — 𝓍^9.
      </TextGroup>
    </FontGenerator>
  )
}

export default Superscript
