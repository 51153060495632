import { upsideDownDict, original } from './constants'

export const upsidedown = (text: string = 'upside down'): string => {
  return text
    .split('')
    .map(e => upsideDownDict[original.indexOf(e)] || e)
    .join('')
}

export const upsidedownAndReversed = (text: string = 'upside down'): string => {
  return text
    .split('').reverse()
    .map(e => upsideDownDict[original.indexOf(e)] || e)
    .join('')
}

export const upsidedownAndReverseSentence = (text: string = 'upside down'): string => {
  return text
    .split('').reverse()
    .map(e => upsideDownDict[original.indexOf(e)] || e)
    .join('')
    .split(' ').reverse().join(' ')
}

// upsideDown () {
//   if (this.message === '') { return 'ndsᴉpǝ poʍu' }
//   return this.message
//     .split('')
//     .map(e => this.upsideDownDict[this.original.indexOf(e)] || e)
//     .join('')
// },
// andReversed () {
//   if (this.message === '') { return 'uʍop ǝpᴉsdn' }
//   return this.message
//     .split('').reverse()
//     .map(e => this.upsideDownDict[this.original.indexOf(e)] || e)
//     .join('')
// },
// reversedSentence () {
//   if (this.message === '') { return 'ǝpᴉsdn uʍop' }
//   return this.message
//     .split('').reverse()
//     .map(e => this.upsideDownDict[this.original.indexOf(e)] || e)
//     .join('')
//     .split(' ').reverse().join(' ')
// }