import type { Meta } from './types'

const unicode = {
  title: 'Unicode Generator Tools | Unicode Font Maker Tagged Unicode',
  description: `
    Unlock a world of Unicode characters and fonts with our powerful Unicode generator tools.
    Transform your text into diverse and captivating Unicode variations
  `,
  h1: 'Unicode generator tools'
}

const tools = {
  title: 'All Font Generator Tools | Unicode Font Maker Tagged Font Maker',
  description: `
    Check out the ultimate Font Maker collection with our font generator tools.
    Create stunning and personalized fonts for your text to amplify its visual impact.
  `,
  h1: 'All font generator tools'
}

const todo = {
  title: 'To-Do Lists Generator Tools | Unicode Font Maker Tagged To-Do Lists',
  description: `
    Generate customized to-do lists in stylish Unicode fonts with our to-do lists generator tools, making your tasks organized and visually appealing.
  `,
  h1: 'To-do lists generator tools'
}

const time = {
  title: 'Time Font Tools | Unicode Font Maker Tagged Time',
  description: `
    Add a time-themed touch to your text using our time font tools.
    Create captivating Unicode fonts that evoke a sense of time, making your content truly unique.
  `,
  h1: 'Time font tools'
}

const text = {
  title: 'Text Generator Tools | Unicode Font Maker Tagged Text',
  description: `
    Generate captivating and expressive Unicode fonts with our text generator tools to elevate your words, giving it a distinct and engaging appearance.
  `,
  h1: 'Text generator tools'
}

const symbols = {
  title: 'Symbol Generator Tools | Unicode Font Maker Tagged Symbols',
  description: `
    Access a vast collection of symbols with our symbol generator tools. Create captivating and visually striking text using unique Unicode symbols.
  `,
  h1: 'Symbol generator tools'
}

const stars = {
  title: 'Stars Generator Tools | Unicode Font Maker Tagged Stars',
  description: `
    Illuminate your text with our stars generator tools. Create enchanting Unicode fonts filled with stars, adding a celestial touch to your content.
  `,
  h1: 'Stars generator tools'
}

const snowflakes = {
  title: 'Snowflakes Generator Tools | Unicode Font Maker Tagged Snowflakes',
  description: `
    Generate stunning Unicode fonts adorned with our snowflakes generator tools, giving your text a frosty and captivating look.
  `,
  h1: 'Snowflakes generator tools'
}

const punctuation = {
  title: 'Punctuation Generator Tools | Unicode Font Maker Tagged Punctuation',
  description: `
    Enhance your text's impact with our punctuation generator tools. Create visually appealing Unicode fonts with unique and artistic punctuation marks.
  `,
  h1: 'Punctuation generator tools'
}

const meta = {
  title: 'Meta Generator Tools | Unicode Font Maker Tagged Meta',
  description: `
    Generate Unicode fonts with meta generator tools that make your meta information visually appealing and engaging. Improve your site's performance now!
  `,
  h1: 'Meta generator tools'
}

const memes = {
  title: 'Memes Generator Tools | Unicode Font Maker Tagged Memes',
  description: `
    Unleash your creativity and generate hilarious memes with our memes generator tools. Create custom Unicode fonts for your meme text.
  `,
  h1: 'Memes generator tools'
}

const italics = {
  title: 'Italic Generator Tools | Unicode Font Maker Tagged Italics',
  description: `
    Add style to your text with our italic generator tools.
    Generate elegant Unicode italic fonts to give your content a sleek and sophisticated appearance.
  `,
  h1: 'Italic generator tools'
}

const hacks = {
  title: 'Hacks Font Tools | Unicode Font Maker Tagged Hacks',
  description: `
    Make hacker-style fonts with our hacks font tools.
    Generate captivating Unicode fonts inspired by coding and hacking, giving your text a tech-savvy look.
  `,
  h1: 'Hacks font tools'
}

const glitch = {
  title: 'Glitch Generator Tools | Unicode Font Maker Tagged Glitch',
  description: `
    Create a glitchy text vibe using our glitch generator tools.
    Create captivating Unicode glitch fonts that add a futuristic and distorted look to your content.
  `,
  h1: 'Glitch generator tools'
}

const fonts = {
  title: 'Font Tools | Unicode Font Maker Tagged Fonts',
  description: `
    Explore a comprehensive collection of font tools designed to elevate your text with Unicode fonts,
    making your content visually appealing and distinctive.
  `,
  h1: 'Font tools'
}

const emoji = {
  title: 'Emoji Tools | Unicode Font Maker Tagged Emoji',
  description: `
    Express yourself with our emoji tools. Generate Unicode fonts with an extensive range of emojis, adding fun and emotions to your text.
  `,
  h1: 'Emoji tools'
}

const dingbats = {
  title: 'Dingbats Text Generator Tools | Unicode Font Maker Tagged Dingbats',
  description: `
    Access a wide variety of decorative symbols with our dingbats text generator tools. Create captivating Unicode fonts adorned with unique dingbats.
  `,
  h1: 'Dingbats text generator tools'
}

const date = {
  title: 'Date Text Generators | Unicode Font Maker Tagged Date',
  description: `
    Highlight important dates with our date text generators.
    Generate visually striking Unicode fonts to showcase dates and create memorable and engaging content.
  `,
  h1: 'Date text generators'
}

const cursive = {
  title: 'Cursive Tools | Unicode Font Maker Tagged Cursive',
  description: `
    Add elegance and flair to your text with our cursive tools.
    Generate captivating Unicode cursive fonts that bring a touch of sophistication to your content.
  `,
  h1: 'Cursive tools'
}

const copyAndPaste = {
  title: 'Copy and Paste Fonts | Unicode Font Maker Tagged Copy and Paste',
  description: `
    Create text styling with our copy and paste fonts feature. Generate Unicode fonts that are easily copied and pasted.
  `,
  h1: 'Copy and paste fonts'
}

const calendar = {
  title: 'Calendar Font Tools | Unicode Font Maker Tagged Calendar',
  description: `
    Stay organized with our calendar font tools. Generate Unicode fonts for calendars, making dates and events visually appealing and easy to read.
  `,
  h1: 'Calendar font tools'
}

export const tagsMeta: Record<string, Meta> = {
  unicode,
  tools,
  'to-do-lists': todo,
  time,
  text,
  symbols,
  stars,
  snowflakes,
  punctuation,
  meta,
  memes,
  italics,
  hacks,
  glitch,
  fonts,
  emoji,
  dingbats,
  date,
  cursive,
  'copy-and-paste': copyAndPaste,
  calendar
}
