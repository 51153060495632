import React from 'react'

import { FontGenerator } from '../index'

import { FocusSection } from '../../../components/FocusSection'
import { TextGroup } from '../../../components/TextGroup'
import { TextWithImage } from '../../../components/TextWithImage'

import { fraktur, frakturBold, script, scriptBold } from '../functions'

import FacebookIcon from './assets/facebook.svg'
import InstagramIcon from './assets/instagram.svg'
import RedditIcon from './assets/reddit.svg'
import EverywhereIcon from './assets/widgets.svg'

import CursiveImg from './assets/cursive.svg'

import styles from './styles.module.scss'
import { underlineTextTool } from '../../tools'

const cursiveVariants = [
  { name: 'Script / cursive / handwriting', fn: script },
  { name: 'Bold script / cursive / handwriting', fn: scriptBold },
  { name: 'Gothic / Blackletter / Fraktur', fn: fraktur },
  { name: 'Bold Gothic / Blackletter / Fraktur', fn: frakturBold }
]

export const previewText = cursiveVariants[0].fn

const tags = [
  { name: 'Unicode' },
  { name: 'Tools' },
  { name: 'Fonts' },
  { name: 'Hacks' },
  { name: 'Text' },
  { name: 'Meta' },
  { name: 'Cursive' }
]

const Cursive: React.FC = () => {
  return (
    <FontGenerator
      variants={cursiveVariants}
      subtitleClassName={styles.subtitle}
      tags={tags}
      moreTools={[underlineTextTool]}
    >
      <TextWithImage
        style={{ marginTop: 64 }}
        title='What is cursive text?'
        image={<CursiveImg />}
      >
        <p>
          Cursive, also known as script, longhand, or joined-up writing, is a style of handwriting
          created without lifting your pen off the paper. It’s generally considered faster to write
          in cursive — and fancier, if you’re doing it properly.
        </p>
      </TextWithImage>
      <TextGroup
        style={{ marginTop: 64 }}
        title='How does this tool work?'
      >
        This tool works by converting regular letters into Mathematical Alphanumeric Symbols.
        These symbols are supposed to be used for algebra, but you can use them to mimic the appearance of cursive handwriting.
      </TextGroup>
      <FocusSection
        title='Where can I use cursive lettering online?'
        subtitle='These cursive characters work on most devices and browsers and are supported by major social media sites.'
        textWithIcon={[
          {
            title: 'Instagram',
            text: 'Instagram users can paste cursive characters into their stories, bios, posts, and instant messages.',
            icon: <InstagramIcon />
          },
          {
            title: 'Facebook',
            text: 'These letters work great on Facebook. They can be used to add a unique element to your posts, photo captions, or instant messages.',
            icon: <FacebookIcon />
          },
          {
            title: 'Reddit',
            text: 'Reddit also accepts the use of these cursive characters. They can be pasted into posts, comments, and more.',
            icon: <RedditIcon />
          },
          {
            title: 'Everywhere else',
            text: 'TikTok, Discord, Twitch, forums, browser tabs, and folders can all feature cursive lettering. You can even use them to name your Wi-Fi network.',
            icon: <EverywhereIcon />
          }
        ]}
      />
    </FontGenerator>
  )
}

export default Cursive
