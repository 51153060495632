import React from 'react'
import useBreadcrumbs from 'use-react-router-breadcrumbs'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import cn from 'classnames'
import urlJoin from 'url-join'
import capitalize from 'lodash.capitalize'

import { fonts } from '../../pages/Home/FontGenerator'
import { copyAndPasteTools } from '../../pages/Home/CopyAndPaste'

import styles from './styles.module.scss'

const VISUAL_HOMEPAGE_URL = urlJoin(process.env.BASE_URL!, '/visual/')
const FONT_MAKER_HOMEPAGE_URL = urlJoin(process.env.BASE_URL!, process.env.BASENAME!)

type Props = {
  className?: string
}

export const Breadcrumps: React.FC<Props> = ({ className }) => {
  const linkToName: Record<string, string> = Object.fromEntries([
    ...fonts.map(i => [i.link ? i.link.replace(/\//g, '') : i.name.toLowerCase().replace(/\s/g, '-'), i.name]),
    ...copyAndPasteTools.map(i => [i.link ?? i.name.toLowerCase().replace(/\s/g, '-'), i.name])
  ])
  const breadcrumbs = useBreadcrumbs()
  const last = breadcrumbs.filter(i => i.key !== '/').pop()

  const keyArr = last?.key.split('/').filter(Boolean)
  const key = keyArr?.[keyArr?.length - 1]
  const pageTitle = key ? linkToName[key] || capitalize(key.replace(/-/g, ' ')) : ''

  const items: Array<{ '@type': 'ListItem', position: number, name: string, item?: string }> = [
    {
      '@type': 'ListItem',
      position: 1,
      name: 'Visual',
      item: VISUAL_HOMEPAGE_URL
    }, {
      '@type': 'ListItem',
      position: 2,
      name: 'Font Maker',
      item: FONT_MAKER_HOMEPAGE_URL
    }
  ]
  if (pageTitle) {
    items.push({
      '@type': 'ListItem',
      position: 3,
      name: pageTitle
    })
  }

  return (
    <>
      <Helmet
        script={[{
          type: 'application/ld+json',
          innerHTML: JSON.stringify({
            '@context': 'https://schema.org',
            '@type': 'BreadcrumbList',
            itemListElement: items
          })
        }]}
      />
      <nav className={cn(styles.container, className)}>
        <a href={VISUAL_HOMEPAGE_URL} className={styles.link}>Visual</a>
        <span className={styles.arrow}>→</span>
        <Link to={FONT_MAKER_HOMEPAGE_URL} className={styles.link}>Font Maker</Link>
        {pageTitle ? <span className={styles.arrow}>→</span> : null}
        {
          pageTitle
            ? (
                <div className={styles.link}>
                  <span>{pageTitle}</span>
                </div>
              )
            : null
        }
      </nav>
    </>
  )
}
