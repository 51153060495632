import React from 'react'
import cn from 'classnames'
import { useLocation } from 'react-router-dom'
import { Helmet } from 'react-helmet'

import { Breadcrumps } from '../Breadcrumbs'
import { Logo } from '../Logo'
import { Title } from '../Title'

import { meta } from './constants'

import styles from './styles.module.scss'

type Props = {
  titleClassName?: string
  subtitleClassName?: string
  children?: React.ReactNode
  className?: string
  headerClassName?: string
}

export const Header: React.FC<Props> = ({ titleClassName, subtitleClassName, children, className, headerClassName }) => {
  const location = useLocation()
  const urlArr = location.pathname.split('/').filter(Boolean)
  const metaForPage = meta[urlArr[urlArr.length - 1]]

  return (
    <>
      <Helmet>
        <meta name="description" content={metaForPage.description} />
        <title>{metaForPage.title}</title>
      </Helmet>
      <header className={cn(styles.container, className)}>
        <div className={cn(styles['header-image'], headerClassName)} />
        <Breadcrumps className={styles.breadcrumbs} />
        <div className={styles['small-container']}>
          <Logo className={styles.logo} />
          <Title className={cn(styles.title, titleClassName)} size={1} h={1} white center>{metaForPage.h1}</Title>
          {metaForPage.subtitle ? <p className={cn(styles.subtitle, subtitleClassName)}>{metaForPage.subtitle}</p> : null}
          {children}
        </div>
      </header>
    </>
  )
}
