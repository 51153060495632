import React from 'react'

import { FocusSection } from '../../../components/FocusSection'
import { TextGroup } from '../../../components/TextGroup'
import { TextTile } from '../../../components/TextTile'
import { TextWithImage } from '../../../components/TextWithImage'
import { Title } from '../../../components/Title'

import { FontGenerator } from '../index'

import { smallCaps } from './functions'

import { tags } from './constants'

import DeviceIcon from './assets/device.svg'
import GlobeIcon from './assets/globe.svg'
import SmallcapsImg from './assets/small-caps.svg'

import styles from './styles.module.scss'

const smallCapsVariants = [
  { name: 'Small caps', fn: smallCaps }
]

export const previewText = smallCapsVariants[0].fn

const SmallCaps: React.FC = () => {
  return (
    <FontGenerator
      variants={smallCapsVariants}
      subtitleClassName={styles.subtitle}
      showUnderline
      tags={tags}
    >
      <TextWithImage
        title='What are small caps?'
        image={<SmallcapsImg />}
        style={{ marginTop: 64 }}
      >
        <>
          <p>
            Put simply, small caps are lowercase letters that resemble uppercase letters.
            The following example compares small caps with traditional uppercase and lowercase letters:
          </p>
          <p>Aᴀa Bʙb Cᴄc</p>
        </>
      </TextWithImage>
      <TextGroup title='When to use small caps' style={{ marginTop: 48 }}>
        <p>Small caps are a subtle way to add emphasis to your writing.</p>
        <p>They’re an authoritative and official-looking alternative to <i>italics</i> or <b>boldface</b> — and quieter than ALL CAPS.</p>
        <p>You can apply them wherever you like, but they have some specific uses, too:</p>
      </TextGroup>
      <TextGroup subtitle='ACRONYMS AND INITIALISMS' style={{ marginTop: 32 }}>
        Small caps are sometimes used instead of ALL CAPS to print acronyms and initialisms, which prevents them from seeming SHOUTED.
      </TextGroup>
      <TextTile style={{ marginTop: 42 }}>
        <p><b>Acronyms</b> are spoken as a single word, e.g. UNESCO, NASA, GIF</p>
        <p><b>Initialisms</b> are spoken letter by letter, e.g. SOS, DVD, UFO</p>
        <i>Garner's Modern American Usage</i>
      </TextTile>
      <TextGroup subtitle='AM AND PM' style={{ marginTop: 32 }}>
        <p>Though not a rule, many editors prefer to use small caps for ᴀᴍ and ᴘᴍ.</p>
        <p>e.g. Class starts at 8 ᴀᴍ and finishes at 4 ᴘᴍ.</p>
      </TextGroup>
      <TextGroup subtitle='AT THE BEGINNING OF A PARAGRAPH' style={{ marginTop: 32 }}>
        Sᴏᴍᴇ ᴘᴜʙʟɪᴄᴀᴛɪᴏɴs ᴜsᴇ sᴍᴀʟʟ ᴄᴀᴘs for the first few words of an article or chapter in a book.
        This style is typically used to help the reader transition from a drop capital into regular lowercase letters.
      </TextGroup>
      <Title className={styles.title} style={{ marginTop: 48 }} size={3}>So, how does this tool work?</Title>
      <div className={styles.tiles}>
        <p>This tool replaces standard Unicode characters with non-standard ones — mostly characters used in the International Phonetic Alphabet (IPA):</p>
        <p>The IPA is a standardized system of phonetic notation used to represent the sound of language. You often see it in dictionary entries:</p>
        <TextTile container={false} autoWith>
          <b>International Phonetic Alphabet</b>
          <p>ᴀ ʙ ᴄ ᴅ ᴇ ɢ ʜ ɪ ᴊ ᴋ ʟ ᴏ ᴘ ʀ ᴛ ᴜ ᴠ ᴡ ʏ ᴢ</p>
          <b>Basic Latin</b>
          <p>ǫ s x</p>
          <b>Cyrillic</b>
          <p>ғ</p>
        </TextTile>
        <TextTile container={false} autoWith>
          <p>dingbat noun ding·bat |\ˈdiŋ-ˌbat\ 1 : a typographical symbol or ornament (such as *, ¶, or ✠)</p>
          <i>Merriam-Webster</i>
        </TextTile>
      </div>
      <FocusSection
        style={{ marginTop: 64 }}
        title='Where can you use small caps?'
        subtitle='You can use small caps almost anywhere online, including:'
        textWithIcon={[
          { title: 'SOCIAL MEDIA', text: 'Small caps work on Reddit, Instagram, Facebook, X, and other social media sites.', icon: <DeviceIcon /> },
          {
            title: 'WEB BROWSERS',
            text: <>
              <p>You can use the small caps generator to name bookmarks and folders in your web browser.</p>
              <p><b>Developers</b> — they also work in title tags, the console, and even URLs.</p>
            </>,
            icon: <GlobeIcon />
          }
        ]}
      />
    </FontGenerator>
  )
}

export default SmallCaps
