import React from 'react'

import { Title } from '../Title'

import styles from './styles.module.scss'

export const WhyNamecheap: React.FC = () => {
  return (
    <div className={styles.container}>
      <Title center>Why Namecheap</Title>
      <div className={styles.row}>
        <div className={styles.card}>
          <b className={styles.title}>Privacy and security</b>
          <p className={styles.text}>
            Your <a className={styles.link} href='https://www.namecheap.com/security/'>website security and privacy</a> comes first at Namecheap, and we will always support the rights of individuals and consumers online.
            It's our mission to keep the Internet open, free, and safe for everyone.
          </p>
        </div>
        <div className={styles.card}>
          <b className={styles.title}>Your business online</b>
          <p className={styles.text}>
            Boost your business with industry-premium products and services, at prices that won't break your budget.
            If it doesn't provide you with a better Internet experience, we simply don't offer it.
          </p>
        </div>
        <div className={styles.card}>
          <b className={styles.title}>Customer service</b>
          <p className={styles.text}>
            You're covered by a <a className={styles.link} href='https://www.namecheap.com/help-center/'>Support Team</a> that's renowned for being one of the most knowledgeable, friendly, and professional in the business.
            Real people are ready to assist you with any issue, any time, 24/7.
          </p>
        </div>
      </div>
    </div>
  )
}
